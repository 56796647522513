<template>
  <div
    class="survey-card"
    :class="{
      '--show-results': showResults,
      '--has-voted': hasVoted,
    }"
  >
    <div v-if="background" class="survey-card__background">
      <nuxt-img
        :src="background"
        format="webp"
        sizes="100vw sm:50vw"
        loading="lazy"
      ></nuxt-img>
    </div>
    <div class="survey-card__content">
      <mks-text>{{ title }}</mks-text>
      <mks-heading tag="h3">{{ survey.title }}</mks-heading>
      <div class="survey-card__choices">
        <button
          v-for="choice in survey.choices"
          :key="choice.id"
          class="survey-card__choice button button--type-neutral button--style-full"
          :class="{ 'survey-card__choice--user-vote': savedVote === choice.id }"
          :style="showResults ? { '--percent': percent(choice.id) } : { '--percent': 0 }"
          :disabled="showResults"
          @click.prevent="sendVote(choice.id)"
          :data-text="
            showResults
              ? `${choice.answer} – ${percentAnimated(choice.id)}`
              : choice.answer
          "
        >
          <span class="survey-card__choice-text"
            >{{ choice.answer }}
            {{ showResults ? `– ${percentAnimated(choice.id)}` : "" }}</span
          >
        </button>

        <mks-loading
          v-if="loading && loadingDebounced"
          type="standalone"
          class="survey-card__loading"
        ></mks-loading>
      </div>

      <div class="survey-card__legend">
        <p v-if="totalVotes > 40 || isClosed">
          {{
            _x("${totalVotes} réponses", "survey-card", {
              totalVotes,
            })
          }}
        </p>
        <p v-else>
          <span v-if="!hasVoted">{{
            _x("Soit parmi les premiers à t'exprimer !", "survey-card")
          }}</span>
          <span v-else>{{ _x("Merci pour ta participation !", "survey-card") }}</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { TransitionPresets, useStorage } from "@vueuse/core";
import { calculatePercentages } from "@/helpers/survey";

const STORAGE_KEY = `makesense_survey`;

const props = defineProps({
  survey: {
    type: Object,
    required: true,
    default: () => ({}),
  },
  background: {
    type: String,
    default: null,
  },
  title: {
    type: String,
    default: null,
  },
});

const loading = shallowRef(false);
const loadingDebounced = refDebounced(loading, 800);
const hasVotedNow = ref(false);
const hasVoted = ref(false);
let savedVote = ref(null);
const votes = ref({});

watchImmediate(props.survey.votes, (value) => {
  votes.value = { ...value };
});

const nuxtApp = useNuxtApp();

onMounted(() => {
  const storage = useStorage(STORAGE_KEY, {}, localStorage, {
    mergeDefaults: true,
  });
  savedVote = computed({
    get: () => storage.value?.[props.survey.builderId],
    set: (value) => {
      storage.value = { ...storage.value, [props.survey.builderId]: value };
      hasVoted.value = true;
    },
  });
  hasVoted.value = !!savedVote.value;
});

const isClosed = computed(() => props.survey.endDate < new Date().getTime());
const showResults = computed(() => hasVoted.value || isClosed.value);

const totalVotes = computed(() =>
  Object.values(votes.value).reduce((acc, curr) => acc + curr, 0)
);

const sendVote = (choiceId) => {
  loading.value = true;
  nuxtApp.$api.Survey.vote(props.survey.builderId, choiceId)
    .then(() => {
      votes.value = { ...votes.value, [choiceId]: (votes.value[choiceId] || 0) + 1 };
      savedVote.value = choiceId;
      hasVotedNow.value = true;
      loading.value = false;
    })
    .catch(() => {
      loading.value = false;
    });
};

const percentages = computed(() => {
  return hasVoted.value
    ? calculatePercentages(
        props.survey.choices.map((choice) => votes.value[choice.id] || 0)
      )
    : props.survey.choices.map((choice) => 0);
});

const percentagesAnimated = useTransition(percentages, {
  delay: 120,
  duration: 1200,
  transition: TransitionPresets.easeOutExpo,
});

const percentagesAnimatedRounded = computed(() => {
  return percentagesAnimated.value.map((value) => Math.round(value));
});

const percent = computed(() => (choiceId) => {
  const value =
    percentages.value[props.survey.choices.findIndex((choice) => choice.id === choiceId)];
  return `${value}%`;
});
const percentAnimated = computed(() => (choiceId) => {
  const value =
    percentagesAnimatedRounded.value[
      props.survey.choices.findIndex((choice) => choice.id === choiceId)
    ];
  return `${value}%`;
});
</script>

<style scoped lang="scss">
.survey-card {
  position: relative;
  overflow: hidden;
  border-radius: $border-radius-xl;

  &__background {
    width: 100%;
    height: 100%;

    aspect-ratio: 3;
    max-height: 200px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: $space-m;
    width: 100%;
    padding: $space-m;
    background: #fff;
    border: 1px solid #e6e6e6;
    border-top: none;
    border-radius: 0 0 $border-radius-xl $border-radius-xl;
  }

  &__loading {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    background: #ffffffaa;
  }

  &__choices {
    display: flex;
    flex-direction: column;
    gap: $space-s;
  }

  &__choice {
    position: relative;
    overflow: hidden;
    appearance: none;
    border-radius: $border-radius-xxl;
    padding: $space-m;
    margin: 0 -3px;
    width: calc(100% + 6px);
    cursor: pointer;
    font-family: $font-text;
    font-weight: $font-weight-black !important;
    font-variant-numeric: tabular-nums;
    text-align: left;
    transition: all 0.63s ease-in-out;

    &:disabled {
      pointer-events: none;
      opacity: 1;
      border-color: #f2f2f2;
      background: #f2f2f2;
    }

    &-text {
      position: relative;
      z-index: 1;
      color: #072a32;
      display: inline-block;
    }

    &:before {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      background: $background-color-primary-active;
      border-radius: $border-radius-xxl;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 2;
      opacity: 0;
      transform: translate3d(-100%, 0, 0);
      transition: opacity 0.2s ease-out, transform 1s cubic-bezier(0.56, 0, 0.27, 1.35);
    }

    &:after {
      content: attr(data-text);
      display: block;
      align-items: center;
      width: 100%;
      height: 100%;
      padding: $space-m;
      color: #fff;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 3;
      clip-path: inset(0 100% 0 0);
      transition: clip-path 1s cubic-bezier(0.56, 0, 0.27, 1.35);
    }

    .--show-results.--has-voted .survey-card__choices &--user-vote {
      &:before {
        background: var(--color-calypso);
      }
      &:after,
      .survey-card__choice-text {
        opacity: 1;
      }
    }

    &:hover {
      transition: all 0.24s ease-in-out;
      background: #d2d2d2;
    }

    &:nth-child(2) {
      &:before,
      &:after {
        transition-delay: 200ms, 200ms;
      }
    }
    &:nth-child(3) {
      &:before,
      &:after {
        transition-delay: 400ms, 400ms;
      }
    }
    &:nth-child(4) {
      &:before,
      &:after {
        transition-delay: 600ms, 600ms;
      }
    }
  }

  &__legend {
    font-size: 0.75rem;
    padding: 0 $space-m;
  }

  &.--show-results .survey-card__choice {
    &:before {
      opacity: 1;
      transform: translate3d(calc(var(--percent, 0) - 100%), 0, 0);
    }

    &:after {
      clip-path: inset(0 calc(100% - var(--percent, 0)) 0 0);
    }
  }

  &.--has-voted .survey-card__choice {
    &:after,
    .survey-card__choice-text {
      opacity: 0.5;
    }
  }
}
</style>
