<script>
import { h } from 'vue'
import MksIcon from '@DS/components/Icon.vue'
import MksButton from '@DS/components/Button.vue'
import MksLink from '@DS/components/Link.vue'
import MksSpacer from '@DS/components/Spacer.vue'
import MksSpacerItem from '@DS/components/SpacerItem.vue'
import ArticleTeaser from '@/components/organisms/Article/Teaser.vue'
import EventTeaser from '@/components/organisms/Event/Teaser.vue'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { useSwiper } from 'swiper/vue'
// import { Navigation } from 'swiper/modules';
import 'swiper/css/navigation'
import { useAsyncData, useNuxtApp } from 'nuxt/app'
export default {
  // setup() {
  //   const swiper = useSwiper();

  //   return {
  //     swiper,
  //     modules: [Navigation]
  //   };
  // },
  props: {
    slidesPerView: {
      type: Number,
      default: 4 // on desktop, TODO breakpoints
    },
    limitSlidesForLargeScreens: {
      type: Boolean,
      default: false
    },
    showNavigation: {
      type: Boolean,
      default: false
    },
    navColor: {
      type: String,
      default: 'primary'
    },
    viewMoreLink: {
      type: String,
      default: null
    },
    viewMoreLabel: {
      type: String,
      default: null
    },
    viewMoreColor: {
      type: String,
      default: 'primary'
    },
    query: {
      type: Object
    },
    ownQuery: {
      type: Object
    },
    articleVariant: {
      type: String,
      default: 'square'
    },
    centerInsufficientSlides: {
      type: Boolean,
      default: false
    },
    useCoverDisplay: {
      type: Boolean,
      default: false
    },
    // used to determine how to render the default slot
    // because builder doesn't pass all items as first level children
    builder: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      swiper: null,
      content: null
    }
  },
  computed: {
    isBeginning() {
      if (!this.swiper) return
      // console.log('isbeginning:', this.swiper.isBeginning)
      return this.swiper.isBeginning
    },
    isEnd() {
      if (!this.swiper) return
      // console.log('isend:', this.swiper.isEnd)
      return this.swiper.isEnd
    },
    isLocked() {
      if (!this.swiper) return
      // console.log('islocked:', this.swiper.isLocked)
      return this.swiper.isLocked
    },
    cssVars() {
      return {
        '--slidesPerView': this.slidesPerView,
        '--slides-mobile': this.slidesPerView <= 3 ? 1.3 : 1.3,
        '--slides-from-540': this.slidesPerView <= 3 ? 1.3 : 2.3,
        '--slides-from-767': this.slidesPerView <= 3 ? 1.5 : 2.3,
        '--slides-from-992': this.slidesPerView + 0.2,
        '--slides-from-1600': this.limitSlidesForLargeScreens
          ? this.slidesPerView + 0.2
          : Math.ceil(this.slidesPerView * 1.2) + 0.2,
        '--slides-from-1900': this.limitSlidesForLargeScreens
          ? this.slidesPerView + 0.2
          : Math.ceil(this.slidesPerView * 1.2) + 0.2,
        '--margin-mobile': 20,
        '--margin-from-540': 20,
        '--margin-from-767': 30,
        '--margin-from-992': 40,
        '--margin-from-1600': 60,
        '--margin-from-1900': 60
      }
    }
  },
  methods: {
    makeBreakpoints() {
      if (this.slidesPerView === 'auto') {
        return {
          slidesPerView: 'auto',
          spaceBetween: 10,
          breakpoints: {
            540: {
              spaceBetween: 20
            },
            // when window width is >= 640px
            992: {
              spaceBetween: 40
            }
          }
        }
      }
      // size big
      else {
        return {
          slidesPerView: this.cssVars['--slides-mobile'],
          spaceBetween: 20,
          breakpoints: {
            // when window width is >= 540px
            540: {
              spaceBetween: this.cssVars['--margin-from-540'],
              slidesPerView: this.cssVars['--slides-from-540']
            },
            767: {
              slidesPerView: this.cssVars['--slides-from-767'],
              spaceBetween: this.cssVars['--margin-from-767']
            },
            992: {
              slidesPerView: this.cssVars['--slides-from-992'],
              spaceBetween: this.cssVars['--margin-from-992']
            },
            1600: {
              slidesPerView: this.cssVars['--slides-from-1600'],
              spaceBetween: this.cssVars['--margin-from-1600']
            },
            1900: {
              slidesPerView: this.cssVars['--slides-from-1900'],
              spaceBetween: this.cssVars['--margin-from-1900']
            }
          }
        }
      }
    }
  },
  async setup(props) {
    const nuxtApp = useNuxtApp()
    if (props.query) {
      const { data: content } = await useAsyncData(props.query.id, () =>
        nuxtApp.$api.Builder.queryContent({ query: props.query })
      )
      return { content }
    } else {
      const ownQuery = props.ownQuery || {}

      if (
        ownQuery.topics?.length ||
        ownQuery.tags?.length ||
        ownQuery.momentums?.length ||
        ownQuery.articles?.length ||
        ownQuery.events?.length
      ) {
        const { data: content } = await useAsyncData(JSON.stringify(ownQuery), () =>
          nuxtApp.$api.Builder.queryContent({ query: props.ownQuery })
        )
        return { content }
      }
    }
  },
  render() {
    let createComponent = h
    const childs = []
    let defaultSlot =
      typeof this.$slots.default === 'function'
        ? this.$slots.default()
        : this.$slots.default

    if (this.builder) defaultSlot = defaultSlot[0].children

    if (this.content) {
      this.content.forEach((item) => {
        let component
        if (item.modelId) {
          component = createComponent(ArticleTeaser, {
            content: item,
            variant: this.articleVariant
          })
        } else {
          // use same variant as articles
          // TODO / ici : use coverDisplay
          component = createComponent(EventTeaser, {
            event: item,
            variant: this.articleVariant,
            useCoverDisplay: this.useCoverDisplay
          })
        }
        childs.push(createComponent(SwiperSlide, () => [component]))
      })
      //
    } else {
      ;(defaultSlot || []).forEach((item, index) => {
        if (item) {
          if (this.builder && index > 0) return
          const itemType = item.type && item.type.toString()
          if (item.type && item.type.name === 'SwiperSlide') {
            childs.push(item)
          } else if (itemType !== 'Symbol(Comment)') {
            if (
              itemType === 'Symbol(Fragment)' ||
              itemType === 'Symbol()' ||
              itemType === 'template' ||
              itemType.match(/^Symbol\(/)
            ) {
              if (Array.isArray(item.children) && item.children.length) {
                item.children.forEach((child) => {
                  if (
                    child.children === ' ' ||
                    (child.el && child.el.nodeName === 'STYLE') ||
                    (child.el && child.el.nodeName === 'COMMENT')
                  ) {
                    return
                  }
                  childs.push(h(SwiperSlide, () => [child]))
                })
              }
              return
            }
            if (item.children === ' ') {
              return
            }
            childs.push(createComponent(SwiperSlide, () => [item]))
          } else {
            const text = item.text && item.text.trim()

            if (item && (text || item.tag)) {
              if (item.componentOptions && item.componentOptions.tag === 'swiper-slide') {
                childs.push(item)
              } else {
                childs.push(createComponent(SwiperSlide, () => [item]))
              }
            }
          }
        }
      })
    }
    if (this.showNavigation || (this.viewMoreLink && this.viewMoreLabel)) {
      const spacerItems = []

      if (this.viewMoreLink && this.viewMoreLabel) {
        const button = createComponent(
          MksButton,
          {
            type: this.viewMoreColor,
            size: 'small',
            icon: 'arrow-right',
            animateIcon: true
          },
          () => this.viewMoreLabel
        )
        const link = createComponent(
          MksLink,
          { to: this.viewMoreLink, type: 'menu' },
          () => button
        )
        spacerItems.push(link)
      }

      if (this.showNavigation) {
        const arrowLeft = createComponent(MksIcon, { type: 'arrow-left' })
        const arrowRight = createComponent(MksIcon, { type: 'arrow-right' })

        const buttons = []
        ;['prev', 'next'].forEach((button) => {
          buttons.push(
            createComponent(
              MksButton,
              {
                class: `carousel-nav carousel-${button}`,
                type: this.navColor,
                onClick: (event) => {
                  this.swiper[button === 'prev' ? 'slidePrev' : 'slideNext']()
                }
              },
              () => [button === 'prev' ? arrowLeft : arrowRight]
            )
          )
        })
        // buttons.push(
        //   createComponent(MksButton, {
        //     class: 'carousel-nav carousel-prev',
        //     type: this.navColor,
        //     onClick: (event) => {
        //       this.swiper.slidePrev()
        //     }
        //   }, [arrowLeft])
        // );
        // buttons.push(
        //   createComponent(MksButton, {
        //     class: 'carousel-nav carousel-next',
        //     type: this.navColor,
        //     onClick: (event) => {
        //       this.swiper.slideNext()
        //     }
        //   }, [arrowRight])
        // );
        spacerItems.push(createComponent(MksSpacerItem, { push: 'right' }, () => buttons))
      }

      childs.push(
        createComponent(
          MksSpacer,
          {
            class: 'carousel-footer',
            inline: true
          },
          () => spacerItems
        )
      )
    }

    return createComponent(
      Swiper,
      {
        class: `mks-carousel ${this.isBeginning ? 'mks-carousel--start ' : ''}${
          this.isEnd ? 'mks-carousel--end ' : ''
        }${this.isLocked ? 'mks-carousel--locked' : ''}`,
        // modules: this.modules,
        ...this.$attrs,
        ...this.$props,
        style: this.cssVars,
        navigation: this.showNavigation
          ? {
              prevEl: 'carousel-prev',
              nextEl: 'carousel-next'
            }
          : false,
        ...this.makeBreakpoints(),
        onSwiper: (swiper) => {
          this.swiper = swiper
        }
      },
      () => childs
    )
  }
}
</script>
<style lang="scss">
// prevent big jumps between server and client render
.mks-carousel:not(.swiper-initialized),
.swiper:not(.swiper-initialized) {
  .swiper-slide {
    width: calc(100% / var(--slides-mobile));
    padding-right: var(--margin-mobile) + 'px';

    @media (min-width: 1600px) {
      width: calc(100% / var(--slides-from-1600));
      padding-right: calc(var(--margin-from-1600) * 1px);
    }

    @media (max-width: 1600px) {
      width: calc(100% / var(--slides-from-992));
      padding-right: calc(var(--margin-from-992) * 1px);
    }

    @media (max-width: 992px) {
      width: calc(100% / var(--slides-from-767));
      padding-right: calc(var(--margin-from-767) * 1px);
    }

    @media (max-width: 767px) {
      width: calc(100% / var(--slides-from-540));
      padding-right: calc(var(--margin-from-540) * 1px);
    }

    @media (max-width: 540px) {
      width: calc(100% / var(--slides-mobile));
      padding-right: calc(var(--margin-mobile) * 1px);
    }
  }
}

.carousel-nav,
.carousel-nav.button {
  @include button-arrow;
  margin-left: $space-xs;
}

.swiper.mks-carousel {
  width: 100%;
  cursor: grab;
  overflow: visible;

  .swiper-wrapper + .spacer {
    margin-top: $space-xs;
    padding-bottom: $space-m;

    @include breakpoint('medium') {
      padding-right: $space-l;
    }
  }

  &--start .carousel-prev,
  &--end .carousel-next {
    opacity: 0.5;
    cursor: initial;
    pointer-events: none;
  }

  &--locked .carousel-nav {
    display: none;
  }

  .carousel-footer {
    margin-right: calc(var(--margin-left) - 20px);
    padding-right: 0 !important;
  }
}

// .swiper .button--more {
//   @include button-icon-transition;
// }

.swiper-button-prev,
.swiper-button-next {
  position: initial;
}
</style>
