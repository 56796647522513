export default {
  methods: {
    getDateNumber(date) {
      return this.$dayjs(date).format('DD')
    },
    getDate(date) {
      return this.$dayjs(date).format('dddd D MMMM')
    },
    getFullDate(date) {
      return (
        this.$dayjs(date).format('dddd D MMMM') +
        ' ' +
        this._x('à', 'à telle heure') +
        ' ' +
        this.$dayjs(date).format('HH:mm')
      )
    },
    getTime(date) {
      return this.$dayjs(date).format('HH:mm')
    },
    relativeDate(date, showTime = false, showDayName = true) {
      let formatted = ''
      if (this.$dayjs(date).isSame(this.$dayjs(), 'day'))
        // return this.$t("list_date_today");
        formatted = this._x("Aujourd'hui", 'Options date : Nom')
      else if (this.$dayjs(date).isSame(this.$dayjs().add(-1, 'days'), 'day'))
        // return this.$t("list_date_yesterday");
        formatted = this.__('Hier')
      else if (this.$dayjs(date).isSame(this.$dayjs().add(-2, 'days'), 'day'))
        // return this.$t("list_date_before_yesterday");
        formatted = this.__('Avant hier')
      else if (this.$dayjs(date).isSame(this.$dayjs().add(1, 'days'), 'day')) {
        // return this.$t("list_date_yesterday");
        formatted = this._x('Demain', 'Options date : Nom')
      }
      // else return this.$dayjs(date).format(this.$t("list_date_day_format"));
      else if (showDayName) formatted = this.$dayjs(date).format('dddd D MMMM')
      else formatted = this.$dayjs(date).format('D MMMM')

      const currentYear = this.$dayjs().year()
      const eventYear = this.$dayjs(date).year()
      if (currentYear !== eventYear)
        formatted += ' ' + this.$dayjs(date).format('YYYY')

      // TODO : if event is + or - 6 months, append year
      if (showTime)
        formatted +=
          ' ' +
          this._x('à', 'à telle heure') +
          ' ' +
          this.$dayjs(date).format('HH:mm')

      return formatted
    },
    relativeDateRange(startDate, endDate, showTime = false) {
      const start = this.$dayjs(startDate)
      const end = this.$dayjs(endDate)

      if (!showTime) {
        if (start.isSame(this.$dayjs(), 'day')) {
          return `Aujourd'hui, jusqu\'au ${end.format('D MMMM')}`
        } else if (start.isSame(this.$dayjs().add(1, 'days'), 'day')) {
          return `Demain, jusqu\'au ${end.format('D MMMM')}`
        } else {
          if (start.month() === end.month()) {
            return `Du ${start.format('D')} au ${end.format('D MMMM')}`
          } else {
            return `Du ${start.format('D MMMM')} au ${end.format('D MMMM')}`
          }
        }
      } else {
        let formatted = ''

        formatted += this.__('Du ${startDate} ', {
          startDate: start.format('D MMMM')
        })

        formatted += this.__('à ${startTime} ', {
          startTime: start.format('HH:mm')
        })

        formatted += this.__('au ${endDate} ', {
          endDate: end.format('D MMMM')
        })

        formatted += this.__('à ${endTime}', {
          endTime: end.format('HH:mm')
        })

        return formatted
      }
    },

    isNow: function (start, end) {
      return this.$dayjs().isAfter(start) && this.$dayjs().isBefore(end)
    },
    startSince: function (date) {
      return Math.round((this.$dayjs() - this.$dayjs(date)) / 60000)
    },
    getDurationMinutes(startDate, endDate) {
      const start = this.$dayjs(startDate)
      const end = this.$dayjs(endDate)
      return end.diff(start, 'minute')
    },
    getDurationText(startDate, endDate) {
      const start = this.$dayjs(startDate).second(0)
      const end = this.$dayjs(endDate).second(0)
      const days = end.diff(start, 'day')
      const hours = end.diff(start, 'hour', true)
      const minutes = end.diff(start, 'minute')
      if (days >= 1) {
        return this.__('${days} jours', { days: days + 1 })
      } else if (hours >= 1) {
        const intHours = parseInt(hours)
        if (intHours === hours) {
          return hours === 1
            ? this.__('1 heure')
            : this.__('${hours} heures', { hours })
        } else if (intHours > 1) {
          return this.__('${hours} heures ${minutes}', {
            hours: intHours,
            minutes: minutes - 60 * intHours
          })
        } else {
          return this.__('${hours} heure ${minutes}', {
            hours: intHours,
            minutes: minutes - 60 * intHours
          })
        }
      } else {
        return this.__('${minutes} minutes', { minutes })
      }
    }
  }
}
