<template>
  <div>
    <div 
      :class="`like ${liked ? 'like--liked': ''} ${animating ? 'like--animate': ''}`" 
      @click.stop.prevent="toggleLike"
    >
      <mks-button
        :id="`trigger-like-${id}`"
        :name="`trigger-like-${id}`"
        aria-label="like"
        tag="button"
        icon="heart"
        input-type="button"
        variant="bare"
        @animationend.native="onIconAnimationEnds"
        ></mks-button
      >
      <transition name="favorite-particles-transition">
        <div v-if="animating" class="like__particles"></div>
      </transition>
    </div>
    <client-only v-if="!liked">
      <tippy :to="`#trigger-like-${id}`">
        <template #content>
          <mks-text size="small">
            <span class="tippy__header">{{__("L'événement t'intéresse mais tu n'es pas encore sûr·e de pouvoir participer ?")}}</span>
          </mks-text>
        </template>
      </tippy>
    </client-only>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  name: 'Like',
  props: {
    type: {
      type: String,
      default: 'event'
    },
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      liked: null,
      isLiking: false,
      animating: false
    }
  },
  created() {
    this.checkLikeStatus();
  },
  activated() {
    this.checkLikeStatus();
  },
  watch: {
    isLoggedIn() {
      if (this.isLoggedIn) {
        this.checkLikeStatus();
      }
    }
  },
  computed: {
    ...mapGetters('user', ['userLoading', 'isLoggedIn', 'profile']),
    hasNeverLikedBefore() {
      return !this.profile.educate || (this.profile.educate && !this.profile.educate.like);
    }
  },
  methods: {
    checkLikeStatus() {
      if (!this.userLoading && this.isLoggedIn)
        this.$api.Like.checkLikeStatus([this.id])
          .then(res => {
            this.liked = res[this.id];
          });
    },
    toggleLike(e) {
      if (this.isLoggedIn) {
        if (this.liked) this.unlike();
        else this.like();
      } else {
        this.$store.dispatch('flowLoginRegister/start', {
          source: 'like',
          feature: 'like-event',
          event: this.id,
          callback: this.like // tester si le user a déjà liké dans le callback
        });
      }
    },
    like() {
      if (this.isLiking) return;
      this.isLiking = true;
      this.liked = true;
      this.animating = true;
      this.$api.Like.create(this.id)
        .then(res => {
          this.isLiking = false;
          this.liked = true;
          this.$emit('liked', this.id);
          this.$toasted.show(this.__("L'évènement est enregistré dans tes favoris."), {
                position: "bottom-center",
                duration: 4000,
                type: "success",
                action : {
                  text : this.__('Voir tous mes favoris'),
                  onClick : (e, toastObject) => {
                    this.$router.push(this.localePath('/account/favorites/events'));
                  }
                },
            });
        })
        .catch(res => {
          this.isLiking = false;
          this.liked = false;
          this.$toasted.show(
            this.__("Une erreur s'est produite"),
            {
              type: "error",
              position: "bottom-center",
              duration: 4000,
            }
          );
        });
    },
    unlike() {
      if (this.isLiking) return;
      this.isLiking = true;
      this.liked = false;
      this.$api.Like.delete(this.id)
        .then(res => {
          this.isLiking = false;
          this.liked = false;
          this.$emit('removed', this.id);
        })
        .catch(res => {
          this.isLiking = false;
          this.liked = true;
          this.$toasted.show(
            this.__("Une erreur s'est produite"),
            {
              type: "error",
              position: "bottom-center",
              duration: 4000,
            }
          );
        });
    },
    onIconAnimationEnds() {
      this.animating = false;
    }
  }
}
</script>
<style lang="scss" scoped>
$particles-animation-duration: 0.8s;
$icon-animation-duration: 0.48s;
$icon-color: $color-tertiary;
$icon-border-color: $color-tertiary;

@keyframes favorite-icon-animation {
  0% {
    opacity: 1;
    transform: scale(0.1);
  }

  50% {
    opacity: 1;
    transform: scale(1.1);
  }

  80% {
    opacity: 1;
    transform: scale(0.9);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes favorite-particles-animation {
  0% {
    background-position: left center;
  }
  100% {
    background-position: right center;
  }
}

// Particles animation.
.favorite-particles-transition-enter-active {
  background-image: url("/assets/images/sprites/particles.png");
  background-size: 2500% auto;
  background-position: left center;
  background-repeat: no-repeat;
  animation-duration: $particles-animation-duration;
  animation-timing-function: steps(24);
  animation-name: favorite-particles-animation;
}

.like {
  position:relative;
  padding: $space-xxs;
  background: $color-neutral-100;
  width: 30px;
  height: 30px;
  border-radius: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .3s cubic-bezier(0.075, 0.82, 0.165, 1);

  &:hover {
    background:$color-neutral-90;
    transform: scale(1.1);
  }
}

.like--animate :deep(.icon){
  opacity: 0;
  transform: scale(0);
  animation-duration: $icon-animation-duration;
  animation-delay: $particles-animation-duration - $icon-animation-duration;
  animation-name: favorite-icon-animation;
}

.like :deep() {
  color: $color-tertiary;
  fill: white;

  .icon{
    transition: fill-opacity 0.2s, stroke 0.2s;
    left: 5px;
    position: relative;
  }

  &:hover{
    color: $color-flame-pea;
    .icon {
      stroke: $color-tertiary;
      transition: fill .3s ease;
      fill: currentColor;
    }
  }
}
.like__particles {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 3em;
  height: 3em;
}

.like--liked :deep() {
  color: $color-danger;
  .icon {
    fill: currentColor;
  }

  &:hover{
    color: $color-tertiary;
  }
}
</style>
