<template>
  <component
    :is="to ? 'mks-link' : 'div'"
    :to="localePath(to)"
    :target="target"
    :class="{
      'mks-component': true,
      card: true,
      'card--elevated': elevated,
      'card--has-image': image,
      'card--portrait': orientation === 'portrait',
      'card--hover': hover,
      'card--small': size === 'small',
      'card--overlay': overlay
    }"
    :style="style"
    type="menu"
  >
    <span class="card__image-background" v-if="image">
      <nuxt-img
        :src="image"
        :alt="imageAlt"
        format="webp"
        sizes="xs:400px sm:800px"
        densities="x1 x2"
        loading="lazy"
      >
      </nuxt-img>
    </span>
    <div v-if="$slots['top']" class="card__top">
      <slot name="top"></slot>
    </div>
    <slot></slot>
    <div v-if="$slots['bottom']" class="card__bottom">
      <slot name="bottom"></slot>
    </div>
  </component>
</template>
<script>
import colorsMixin from "@/mixins/colors";
export default {
  mixins: [colorsMixin],
  props: {
    image: {
      type: String, // image url or COLOR
      required: false
    },
    imageAlt: {
      type: String,
      required: false,
      default: ""
    },
    elevated: {
      type: Boolean,
      default: true
    },
    to: {
      type: String
    },
    orientation: {
      type: String,
      default: "auto" // auto, portrait, landscape
    },
    hover: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: "normal" // small, normal, large
    },
    overlay: {
      type: Boolean,
      default: false
    },
    target: {
      type: String
    },
    background: {
      type: String
    },
    textColor: {
      type: String
    }
  },
  computed: {
    style() {
      let style = "";
      // if (this.image) style = `--card-bg-image: ${this.backgroundImage}`;
      if (!this.image && this.background)
        style = this.getColorStyles("background", this.background);
      if (this.textColor) style += this.getColorStyles("color", this.textColor);
      return style;
    }
  }
};
</script>
<style lang="scss">
.mks-component.card.card--has-image {
  position: relative;
  overflow: hidden;
  background-color: transparent;
  .card__image-background {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    z-index: 0;
    transition: all 0.3s ease-in-out;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &:hover {
    .card__image-background {
      transform: scale(1.03);
    }
  }
}
.mks-component.card.card--overlay.card--has-image {
  &:before {
    content: "";
    background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 57.16%,
      rgba(0, 0, 0, 0.97) 100%
    );
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
  }
}
.mks-component.card {
  display: flex;
  transition: transform 0.3s ease;
  flex-direction: column;
  user-select: none;
  position: relative;
  max-width: 100%;
  text-align: left;

  &:hover {
    opacity: 1 !important;

    .card__hover {
      transform: rotate(-0.5deg);
    }
  }

  &--hover {
    &:hover {
      transform: rotate(-1deg);
    }
  }

  &--elevated {
    @include card-neutral;
    padding: $space-m;

    @include breakpoint("medium") {
      padding: $space-l;
    }
  }
  &--has-image {
    background-size: cover;
    border: none !important;
    transition: all 0.3s ease-in-out;
    background-position: center;
    // background-size: cover;

    // &:hover {
    //   background-size: 105%;
    // }
  }

  &--portrait {
    aspect-ratio: 0.84; // todo : check si ça marche dans d'autres browser // ajouter en props aussi ?
    min-height: 300px;
  }

  // &--landscape {
  //   aspect-ratio:1.79; // todo : check si ça marche dans d'autres browser // ajouter en props aussi ?
  //   min-height: 300px;
  // }

  &--small {
    padding: $space-s;
    border-radius: $space-m;

    @include breakpoint("medium") {
      padding: $space-m;
    }
  }

  &:focus {
    border-radius: $border-radius-xl !important;
  }
}
.card__top {
  margin-bottom: auto;
  z-index: 1;
}

.card__bottom {
  margin-top: auto;
  padding-top: $space-m;
  z-index: 1;
}

.card__hover {
  transition: transform 0.3s ease;
}
</style>
