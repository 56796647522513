<template>
  <SurveyCard
    v-if="survey"
    :survey="survey"
    :background="background"
    :title="title"
    class="current-survey"
  />
</template>

<script setup>
import SurveyCard from "./SurveyCard.vue";
const nuxtApp = useNuxtApp();

const props = defineProps({
  background: {
    type: String,
    default: null,
  },
  title: {
    type: String,
    default: null,
  },
});
const route = useRoute();
const previewing = route.query.previewing;

const timestamp = new Date().getTime();

const { data } = await useAsyncData(() =>
  nuxtApp.$builder.getOne("sondage", {
    query: {
      "data.startDate.$lte": timestamp,
    },
    options: {
      sort: "-data.startDate",
    },
    includeUnpublished: previewing === "true",
  })
);

const survey = computed(() => {
  if (!data.value) return null;
  return {
    ...data.value.data,
    votes: data.value.votes,
    builderId: data.value.builderId,
  };
});
</script>
