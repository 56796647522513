import axios from "axios";
export default defineNuxtPlugin((NuxtApp) => {
  const $axios = axios;
  const $env = NuxtApp.$env;
  const getCookie = function (name) {
    if (process.client) {
      let v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
      return v ? v[2] : null;
    }
    return null;
  };

  // Create a custom axios instance
  const axiosInstance = $axios.create({
    baseURL: `${$env.apiUrl}${$env.version}`
  });
  const api = {};
  let tokens = {
    token: getCookie('makesense_events_token'),
    refreshToken: getCookie('makesense_events_refresh_token')
  };

  const setTokens = (token, refreshToken) => {
    document.cookie = `makesense_events_token=${token}; max-age=${60 * 60 * 24}; path=/`;
    document.cookie = `makesense_events_refresh_token=${refreshToken}; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/`;
    tokens.token = token;
    tokens.refreshToken = refreshToken;
  };

  const getToken = () => {
    return tokens.token;
  }


  const clearTokens = () => {
    document.cookie = `makesense_events_token=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/`;
    document.cookie = `makesense_events_refresh_token=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/;`;
    tokens.token = null;
    tokens.refreshToken = null;
  };

  axiosInstance.interceptors.request.use((config) => {
    if (tokens && tokens.token) {
      config.headers.Authorization = `Bearer ${tokens.token}`;
    }
    return config;
  });

  axiosInstance.interceptors.response.use((response) => response.data, async (error) => {
    if (error.response && error.response.status === 401) {
      if (error.config && !error.config.url.match(/^\/auth\/local/)) {
        if (error.config.headers.Authorization && !error.config._tokenRefreshed && tokens.refreshToken) {
          await api.Auth.refreshToken(tokens.refreshToken);
          error.config._tokenRefreshed = true;
          return axiosInstance(error.config);
        }
      }
    }
    return Promise.reject(error);
  });
  // Set baseURL to something different


  api.Advert = {
    live() {
      return axiosInstance.get("/strevda/live");
    }
  };
  api.Document = {
    upload(params) {
      return axiosInstance.post("/upload", params, {
        headers: { "Content-Type": "multipart/form-data" }
      });
    }
  };
  api.Game = {
    getWinnerCount() {
      return axiosInstance.get('/game/winners');
    },
    getWord() {
      return axiosInstance.get('/game/word');
    },
    isValid(word) {
      return axiosInstance.post('/game/word/valid', {
        word
      })
    },
    addWinner() {
      return axiosInstance.post('/game/winner');
    },
    saveScore(result) {
      return axiosInstance.post(`/game/score`, result)
    }
  }
  api.Events = {
    query(params) {
      return axiosInstance.get("/events", {
        params
      });
    },
    bySlug(slug) {
      return axiosInstance.get(`/events/slug/${slug}`);
    },
    related(id) {
      return axiosInstance.get(`/events/related/${id}`);
    },
    /**
     * @param email
     * @param transactionnal
     * @param acceptPhoto
     */
    attend(id, data) {
      return axiosInstance.post(`/events/attend/${id}`, data);
    },
    subAndAttend(id, data) {
      return axiosInstance.post(`/events/subscribe-attend/${id}`, data);
    },
    unAttend(id, params) {
      return axiosInstance.delete(`/events/attend/${id}`, params);
    },
    feedback(id, data) {
      return axiosInstance.post(`/events/feedback/${id}`, data);
    },
    contact(id, data) {
      return axiosInstance.post(`/events/contact/${id}`, data);
    },
    generateCover(data) {
      return axiosInstance.post(`/events/cover`, data);
    },
    create(data) {
      return axiosInstance.post(`/events/`, data)
    },
    update(data) {
      return axiosInstance.put(`/events/${data._id}`, data)
    },
    checkin(id, data) {
      return axiosInstance.post(`/events/checkin/${id}`, data);
    },
    checkout(id, data) {
      return axiosInstance.post(`/events/checkout/${id}`, data);
    },
    batch(id, data) {
      return axiosInstance.post(`/events/batch/${id}`, data);
    },
    addCoorganizer(id, data) {
      return axiosInstance.post(`/events/${id}/coorganizer`, data)
    }
  };
  api.Iframe = {
    getEvents(id) {
      return axiosInstance.get(`/iframes/${id}/events`);
    },
    getDetails(id) {
      return axiosInstance.get(`/iframes/${id}`);
    }
  };
  api.Taxonomy = {
    query(params) {
      return axiosInstance.get("/taxonomies", {
        params: params
      });
    },
    bySlug(slug, params) {
      return axiosInstance.get(`/taxonomies/by-slug/${slug}`, {
        params: params
      });
    },
    byId(id, params) {
      return axiosInstance.get(`/taxonomies/${id}`, {
        params: params
      });
    }
  };
  api.Hotspot = {
    query(params) {
      return axiosInstance.get("/hotspots", {
        params: params
      });
    },
    bySlug(slug, params) {
      return axiosInstance.get(`/hotspots/by-slug/${slug}`, {
        params: params
      });
    }
  };
  api.Translation = {
    query(params) {
      return axiosInstance.get("/translations", params);
    }
  };
  api.Seo = {
    byKey(key) {
      return axiosInstance.get(`/seo/key/${key}`);
    }
  };

  api.Auth = {
    async login(email, password) {
      const response = await axiosInstance.post("/auth/local", {email, password});
      setTokens(response.token, response.refreshToken);
      return true;
    },
    async refreshToken(refreshToken) {
      const response = await axiosInstance.post("/auth/local/token", {refreshToken});
      setTokens(response.token, response.refreshToken);
      return true;
    },
    providerLogin(provider) {
      return new Promise((resolve, reject) => {
        const windowInstance = window.open(`${$env.apiUrl}${$env.version}/auth/${provider}`, 'Login', 'width=640,height=480');
        const handler = async (e) => {
          if (typeof e.data === 'string' && e.data.substring(0, 3) === 'OK:') {
            const response = JSON.parse(e.data.substring(3));
            setTokens(response.token, response.refresh);

            windowInstance.close();
            window.removeEventListener('message', handler, false);
            resolve();
          }
          else if (e.data.match(/^ERROR:/)) {
            window.removeEventListener('message', handler, false);
            windowInstance.close();
            reject(e.data);
          }
        };
        window.addEventListener('message', handler, false);
      });
    },
    setTokens(token, refreshToken) {
      setTokens(token, refreshToken);
    },
    getToken() {
      return tokens.token;
    },
    hasToken() {
      return !!(tokens.token || tokens.refreshToken);
    },
    logout() {
      clearTokens();
    }
  };

  api.User = {
    exists(email) {
      return axiosInstance.post("/users/exists", {email});
    },
    register(data) {
      return axiosInstance.post("/users/register/events", data);
    },
    changePassword(data) {
      return axiosInstance.put(`/users/${data._id}/password`, data);
    },
    lostPassword(email) {
      return axiosInstance.post("/users/lost-password/events", {email, site: $env.SITE});
    },
    me() {
      return axiosInstance.get("/users/me");
    },
    update(userData) {
      return axiosInstance.put(`/users/${userData._id}`, userData);
    },
    events(params) {
      return axiosInstance.get("/users/events", {params});
    },
    activate(token) {
      return axiosInstance.post("/users/activate", {token});
    },
    resendActivationEmail(email) {
      return axiosInstance.post('/users/resend-activation', {email});
    },
    checkToken(token) {
      return axiosInstance.post('/users/check-token', {token});
    },
    resetPassword(password, token) {
      return axiosInstance.post('/users/reset-password', {password, token});
    },
    subscribeNewsletter(params) {
      return axiosInstance.post('/users/subscribe-nl', params)
    }
  };

  api.Like = {
    get() {
      return axiosInstance.get("/likes");
    },
    getLikes(events) {
      return axiosInstance.post("/likes/events", {events});
    },
    create(event) {
      return axiosInstance.post("/likes", {event});
    },
    delete(event) {
      return axiosInstance.delete("/likes", {data: {event}});
    },
    checkLikeStatus(events) {
      return axiosInstance.post("/likes/events", {events});
    }
  };

  api.Survey = {
    vote(id, choiceId) {
      return axiosInstance.post(`/builder/vote/${id}`, {answer: choiceId});
    },
  };
  
  api.Builder = {
    getRef(ref) {
      return axiosInstance.post("/builder/getref", {ref});
    },
    getRefs(refs) {
      return axiosInstance.post("/builder/getrefs", {refs});
    },
    getOne(model, params) {
      return axiosInstance.post("/builder/getone", {model, params});
    },
    getAll(model, params) {
      return axiosInstance.post("/builder/getall", {model, params});
    },
    queryContent(params) {
      return axiosInstance.post("/builder/querycontent", params);
    },
    search(params) {
      return axiosInstance.get("/builder/search", {params});
    },
    track(slug) {
      console.log('tracking', slug);
      return axiosInstance.post("/builder/track", {slug});
    }
  };
  
  
  
  NuxtApp.$store.$api = api;
  // Inject to context as $api
  return {
    provide: {
      api
    }
  }
})
