<template>
  <Card
    class="mks-component teaser"
    v-if="variant === 'text'"
    hover
    :to="to"
    :target="target"
  >
    <Tag
      class="teaser__tag"
      :color="tagColor"
      size="small"
      :icon="tagIcon"
      icon-stroke="3"
      >{{ tagLabel }}</Tag
    >
    <mks-heading
      tag="h3"
      class="teaser__heading"
      color="neutral-0"
      v-html="title"
    ></mks-heading>
    <mks-text class="teaser__description" size="small">{{ text }}</mks-text>
  </Card>
  <Card
    v-else-if="variant === 'spotlight' || variant === 'spotlight-auto'"
    class="mks-component teaser"
    :image="image"
    :imageAlt="
      event?.coverAltText
        ? event.coverAltText
        : event?.title
        ? event.title[event.lang]
        : ''
    "
    :to="to"
    :target="target"
    :orientation="variant === 'spotlight-auto' ? 'auto' : 'portrait'"
    :overlay="!!event"
  >
    <template v-slot:top v-if="event && event.date">
      <EventDate :date="event.date" />
    </template>
    <template v-slot:bottom>
      <div class="card__hover">
        <template v-if="!event || variant === 'spotlight'">
          <Tag
            v-if="tagLabel"
            class="teaser__tag"
            :color="tagTextColor"
            :background="tagBackgroundColor"
            size="small"
            :icon="tagIcon"
            icon-stroke="3"
            >{{ tagLabel }}</Tag
          >
          <Tag icon="check" background="success" size="small" v-if="alreadyRegistered"
            >Je participe</Tag
          >
        </template>
        <HeadingBean
          tag="h3"
          tag-class="h3"
          class="teaser__heading"
          color="neutral-0"
          :text="title"
        >
        </HeadingBean>
        <div class="teaser__metas">
          <div v-if="event && variant === 'spotlight-auto' && variant !== 'spotlight'">
            <Tag
              class="teaser__tag"
              :color="tagTextColor"
              :background="tagBackgroundColor"
              size="small"
              :icon="tagIcon"
              icon-stroke="3"
              >{{ tagLabel }}</Tag
            >
            <Tag icon="check" background="success" size="small" v-if="alreadyRegistered"
              >Je participe</Tag
            >
          </div>
          <EventMetas
            v-if="event"
            :event="event"
            color="neutral-100"
            showDate
          ></EventMetas>
        </div>
      </div>
    </template>
  </Card>
  <Card v-else-if="variant === 'flat'" :elevated="false" :to="to" :target="target">
    <div class="teaser__cover-wrapper teaser__cover-wrapper--portrait">
      <nuxt-img
        v-if="image"
        class="teaser__image"
        :class="{ 'teaser__image--greyed': isPastEvent }"
        :src="image"
        :alt="
          event?.coverAltText
            ? event.coverAltText
            : event?.title
            ? event.title[event.lang]
            : ''
        "
        format="webp"
        sizes="xs:550px sm:800px"
        loading="lazy"
      >
      </nuxt-img>
      <!-- <div v-else class="teaser__image teaser__image--portrait teaser__image--placeholder"></div> -->
      <mks-spacer inline align="center" class="teaser__like">
        <mks-tag icon="check" color="success" v-if="alreadyRegistered"
          >Je participe</mks-tag
        >
        <Like
          v-if="showLikeOnCover && event && !isPastEvent"
          :id="event._id"
          @liked="$emit('liked', event._id)"
          @removed="$emit('removed', event._id)"
        ></Like>
      </mks-spacer>
      <div v-if="isPastEvent" class="teaser__past">
        <mks-tag color="negative">Événement passé</mks-tag>
      </div>
    </div>

    <Tag
      v-if="!event && tagLabel"
      class="teaser__tag"
      :color="tagColor"
      icon-stroke="3"
      size="small"
      :icon="tagIcon"
      >{{ tagLabel }}</Tag
    >
    <HeadingBean
      tag="h3"
      class="teaser__heading card__hover"
      background="neutral-90"
      color="neutral-0"
      :text="title"
    >
    </HeadingBean>
    <mks-text
      v-if="text && showDescription"
      class="teaser__description"
      size="small"
      tag="div"
      v-html="strippedText"
    ></mks-text>
    <Date
      class="teaser__date"
      v-if="event"
      :event="event"
      showLocation
      showDuration
      greyPastEvents
    ></Date>
  </Card>
  <Card v-else-if="variant === 'square'" :elevated="false" :to="to" :target="target">
    <div class="teaser__floating-date">
      <EventDate v-if="event" :date="event.date" /><br /><br />
      <Tag icon="check" background="success" size="small" v-if="alreadyRegistered"
        >Je participe</Tag
      >
    </div>
    <div class="teaser__cover-wrapper teaser__cover-wrapper--square">
      <span class="teaser__image-icon" v-if="tagIcon">
        <IconContent :type="tagIcon" fill="white" :width="50" :height="50"></IconContent>
      </span>
      <nuxt-img
        v-if="image"
        class="teaser__image"
        :src="image"
        format="webp"
        sizes="xs:400px sm:700px"
        densities="x1 x2"
        loading="lazy"
        :alt="
          event?.coverAltText
            ? event.coverAltText
            : event?.title
            ? event.title[event.lang]
            : ''
        "
      >
      </nuxt-img>
    </div>
    <Tag
      class="teaser__tag"
      :color="tagColor"
      size="small"
      :icon="tagIcon"
      icon-stroke="3"
      >{{ tagLabel }}</Tag
    >
    <HeadingBean
      tag="h3"
      tag-class="h4"
      class="teaser__heading teaser__heading--cut card__hover"
      background="neutral-90"
      color="neutral-0"
      :text="title"
    >
    </HeadingBean>
    <!-- <EventMetas v-if="event" :event="event" color="inherit" showDate></EventMetas> -->
  </Card>
  <Card
    v-else-if="variant === 'line' || variant === 'line-small'"
    :to="to"
    :target="target"
    size="small"
    hover
  >
    <div class="teaser-line" inline align="center" nowrap>
      <!-- <mks-date-number v-if="event" class="teaser__image--small teaser__mini-date" :number="$dayjs(date).format('DD')" color="tertiary"></mks-date-number> -->
      <nuxt-img
        v-if="image"
        class="teaser__image--small"
        :src="image"
        format="webp"
        sizes="120px xs:120px"
        loading="lazy"
        :alt="
          event?.coverAltText
            ? event.coverAltText
            : event?.title
            ? event.title[event.lang]
            : ''
        "
      ></nuxt-img>
      <div v-else class="teaser__image--small teaser__image--placeholder"></div>
      <div class="teaser-line__content">
        <mks-heading
          tag="h3"
          :tag-class="event ? 'h5' : variant === 'line-small' ? 'h6' : 'h4'"
          class="teaser__heading teaser-heading--thin teaser__heading--cut3"
          color="neutral-0"
          v-html="title"
        ></mks-heading>
        <EventMetas v-if="event" :event="event" color="light" showDate></EventMetas>
      </div>
      <div v-if="!event" class="teaser-line__right">
        <mks-button :type="event ? 'primary' : 'atlantis'" size="small">
          <mks-icon
            type="arrow-right"
            :size="20"
            style="width: 20px; height: 20px; margin: 0 5px"
          ></mks-icon>
        </mks-button>
      </div>
    </div>
  </Card>
  <Card
    v-else-if="variant === 'sidebar-line'"
    :to="to"
    :target="target"
    size="small"
    hover
  >
    <div class="teaser-sidebar teaser-line" inline align="center" nowrap>
      <nuxt-img
        v-if="image"
        placeholder="./placeholder-grey.png"
        class="teaser__image--small"
        :src="image"
        format="webp"
        sizes="120px xs:120px"
        loading="lazy"
        :alt="
          event?.coverAltText
            ? event.coverAltText
            : event?.title
            ? event.title[event.lang]
            : ''
        "
      ></nuxt-img>
      <div v-else class="teaser__image--small teaser__image--placeholder"></div>
      <div class="teaser-line__content">
        <div class="teaser__date" v-if="event">
          <mks-date-number
            class="teaser__date-number"
            :number="$dayjs(event.date).format('DD')"
            color="tertiary"
          ></mks-date-number>
          <span class="teaser__date-text">{{ $dayjs(event.date).format("D MMMM") }}</span>
        </div>
        <mks-heading
          tag="h3"
          tag-class="h4"
          class="teaser__heading teaser-heading--thin teaser__heading--cut3"
          color="neutral-0"
          v-html="title"
        ></mks-heading>
        <EventMetas
          v-if="event"
          :event="event"
          color="light"
          showDate
          class="teaser__metas"
        ></EventMetas>
      </div>
      <div class="teaser-line__right">
        <mks-button
          :type="event ? 'primary' : 'atlantis'"
          icon="arrow-right"
          size="small"
        ></mks-button>
      </div>
    </div>
  </Card>
</template>
<script>
import { mapGetters } from "vuex";
import Card from "../molecules/Cards/Card.vue";
import EventMetas from "../molecules/Event/EventMetas.vue";
import HeadingBean from "../atoms/HeadingBean.vue";
import Tag from "../atoms/Tag.vue";
import striptags from "striptags";
export default {
  components: { EventMetas, Tag, HeadingBean, Card },
  props: {
    content: {
      type: Object
    },
    variant: {
      type: String,
      default: "text" // "text", "spolight", "spotlight-auto", "flat", "line", "line-small", "square"
    },
    to: {
      type: String
    },
    image: {
      type: String
    },
    title: {
      type: String
    },
    text: {
      type: String
    },
    tagLabel: {
      type: String
    },
    tagIcon: {
      type: String
    },
    event: {
      type: Object
    },
    isPastEvent: {
      type: Boolean,
      default: false
    },
    tagColor: {
      type: String
    },
    tagBGColor: {
      type: String
    },
    showLikeOnCover: {
      type: Boolean,
      default: true
    },
    showDescription: {
      type: Boolean,
      default: true
    },
    target: {
      type: String
    },
    source: {
      type: String
    },
    alreadyRegistered: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters("user", ["userLoading", "isLoggedIn", "profile"]),
    cutTitle() {
      // if (this.title.length > 80) return this.title && this.title.substring(0,100) + '...';
      // else return this.title;
      return this.title;
    },
    strippedText() {
      return striptags(this.text);
    },
    tagTextColor() {
      if (this.tagColor === "roman" && !this.tagBGColor) {
        return "neutral-100";
      }
      return this.tagColor;
    },
    tagBackgroundColor() {
      if (!this.tagBGColor) {
        if (this.tagColor === "parsley") {
          return "atlantis";
        }
        if (this.tagColor === "roman") {
          return "roman";
        }
      }
      return this.tagBGColor;
    }
  }
};
</script>
<style lang="scss" scoped>
.teaser {
  max-height: 100%;
}

.teaser__like {
  position: absolute;
  top: $space-m;
  right: $space-l;
}

.teaser__past {
  position: absolute;
  bottom: $space-m;
  right: $space-l;
}

.teaser__cover-wrapper {
  position: relative;
  background: $color-neutral-90;
  margin-bottom: $space-m;
  border-radius: $border-radius-l;
  overflow: hidden;

  &--portrait {
    aspect-ratio: 1.8;
    object-fit: cover;
    border-radius: $border-radius-m;
  }

  &--square {
    aspect-ratio: 1;
    object-fit: cover;
  }
}

.teaser__image {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;

  &--small {
    max-width: 70px;
    aspect-ratio: 1;
    object-fit: cover;
    border-radius: $border-radius-m;
  }

  .teaser__cover-wrapper--portrait & {
    border-radius: $border-radius-m;
  }

  .teaser__cover-wrapper--square & {
    border-radius: $border-radius-m;
    min-height: 100%;
  }

  &--placeholder {
    background: $color-neutral-80;
  }

  &--greyed {
    mix-blend-mode: luminosity;
  }
}
.mks-spacer-item--shrink {
  flex-shrink: 1 !important;
}
.teaser__heading {
  font-family: $font-heading !important;
  font-weight: $font-weight-extrabold !important;
  line-height: $line-height-s !important;
  margin-bottom: $space-xs;

  &--cut {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4; /* number of lines to show */
    -webkit-box-orient: vertical;

    // empêcher que ça coupe avec le overflow hidden qui limite le nombre de lignes
    padding-top: 3px;
    padding-bottom: 1.5px;
    padding-left: 1px;
    padding-right: 3px;
    margin-right: 1px;
  }

  &--cut3 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    -webkit-box-orient: vertical;
  }

  &.teaser-heading--thin {
    font-weight: $font-weight-bold !important;
  }

  @include breakpoint("small", "max") {
    font-size: 22px !important;
  }
}
.teaser__tag {
  margin-bottom: $space-s;
}
.teaser__description {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4; /* number of lines to show */
  line-clamp: 4;
  -webkit-box-orient: vertical;
  margin-bottom: $space-s;
}

.teaser__metas {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  & > * {
    margin-bottom: $space-xxs;
  }
}

.teaser__date {
  margin-top: 0 !important;
}
.teaser__floating-date {
  position: absolute;
  top: $space-m;
  left: $space-m;
  z-index: 1;

  time {
    padding: 5px;
  }
}

.teaser-line {
  display: flex;
  align-items: center;

  @include breakpoint("small", "max") {
    .teaser__heading {
      font-size: $font-size-m !important;
    }

    .teaser-line__content {
      margin: 0;
      margin-left: $space-xs;
    }

    .teaser-line__right {
      max-width: 25px;

      button {
        max-width: 100%;
        min-width: 0 !important;
        text-align: center;
        padding-left: 2px;
      }
    }
  }
}

.teaser-line__content {
  margin-left: $space-m;
  margin-right: $space-m;
  text-align: left;
}

.teaser-line__right {
  margin-left: auto;

  .button svg {
    margin-right: 0;
    width: 25px;
    height: 25px;
  }
}

.teaser__image-icon {
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.2s ease;

  // svg {
  //   width: 50px;
  //   height: 50px;
  // }
}
.card:hover .teaser__image-icon {
  opacity: 1;
  transform: translate(-50%, -50%) scale(2);

  // svg {
  //   width: 100px;
  //   height: 100px;
  // }
}

.teaser__mini-date {
  min-width: 40px;
}
.teaser-sidebar {
  .teaser__image--small {
    align-self: start;
  }

  .teaser__date {
    background: #fff;
    border: 1px solid #e6e6e6;
    border-radius: var(--border-radius-m);
    padding: 8px;
    display: inline-flex;
    align-items: center;
    margin-bottom: var(--space-xs);

    &-text {
      padding-left: 8px;
      font-size: 16px;
      font-weight: 700;
    }

    :deep(.date) {
      width: 30px;
      height: 30px;
      font-size: 15px;
      transform: none;
    }
  }

  .teaser__metas {
    flex-direction: column;
    align-items: start;

    :deep(span:not(:last-child)) {
      padding-bottom: var(--space-xs);
    }
  }
}
</style>
