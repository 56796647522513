<template>
  <div id="events-list" :class="{
    'filter-events': $env.SITE === 'international'
  }" >
    <CustomHead :head="head" />
    <Filters
      v-if="showFilters"
      :class="{'filters': $env.SITE === 'international'}"
      v-model="filters"
      ref="event-top"
      :show="showFilters"
      :dateBetween="dateBetween"
      @reset="resetFilters"
    >
      <template v-if="showQuickSearch" v-slot:quicksearch>
        <div>
          <mks-text size="small">{{ __('Recherches rapides : ') }}</mks-text>
          <MksTag
            v-if="isLoggedIn && userTopic && userTopic.label"
            size="small"
            :label="userTopic.label"
            icon="search"
            :color="filters.topic === userTopic.slug ? 'broom' : undefined"
            icon-left
            cliquable
            style="--color-hover: #910045"
            @click="setFilter('topic', filters.topic ? null : userTopic.slug)"
          >
          </MksTag>
          <MksTag
            v-if="lang === 'fr'"
            size="small"
            label="Organisé par makesense"
            icon="search"
            :color="filters.makesense === true ? 'cerise' : undefined"
            icon-left
            cliquable
            style="--color-hover: #910045"
            @click="setFilter('makesense', filters.makesense ? false : true)"
          >
          </MksTag>
          <MksTag
            size="small"
            :label="__('En ligne')"
            icon="search"
            :color="filters.visio === true ? 'primary' : undefined"
            icon-left
            cliquable
            style="--color-hover: #d9575a"
            @click="setFilter('visio', filters.visio ? false : true)"
          >
          </MksTag>
          <!--MksTag
            v-if="lang === 'fr'"
            size="small"
            icon="search"
            :class="{ tag__paumees: filters.momentum === 'paumees' }"
            icon-left
            cliquable
            @click="getMomentum('paumees')"
          >
            Paumé.e.s</MksTag
          -->

          <!-- <MksTag
            v-if="lang === 'fr'"
            size="small"
            label="Ramener la coupe à la raison"
            icon="search"
            :color="filters.momentum === 'ramener-la-coupe-a-la-raison' ? 'parsley' : undefined"
            icon-left
            cliquable
            @click="getMomentum('ramener-la-coupe-a-la-raison')" >
          </MksTag> -->
          <MksTag
            v-if="lang === 'es'"
            size="small"
            label="Makesense Americas"
            icon="search"
            :color="filters.momentum === 'mks-mexico' ? 'parsley' : undefined"
            icon-left
            cliquable
            @click="getMomentum('mks-mexico')">
          </MksTag>
        </div>
      </template>
      <template v-slot:results>
        <mks-tag size="small" variant="ghost"
          >{{ total }} {{ __("résultats") }}</mks-tag
        >
        <!-- <span v-if="pages">{{ `${__("Page")} ${page + 1} / ${pages}` }}</span>  ·  -->
      </template>
    </Filters>
    <!-- events {{ events }} -->
    <div v-if="events && events.length > 0" class="events">
      <EventList
        :type="listType"
        :events="events"
        :layout="layout"
        :eventSpacing="isIndexPage ? 'large' : 'small'"
        :target="isIndexPage ? '_self' : '_blank'"
        :source="source">
      </EventList>
      <mks-pagination
        v-if="showPagination"
        class="events__pagination"
        :total="pages"
        :value="page + 1"
        @change="updateCurrentPage"
      ></mks-pagination>
    </div>
    <!--div class="chiche-pub mks-grid mks-grid-gap-lg" v-if="lang !== 'fr'">
      <mks-spacer class="chiche-text mks-col-6@md">
        <mks-heading tag="h3" tag-class="h2" color="inherit">{{ __('Hey, hunting for events in France?') }}</mks-heading>
        <mks-text>{{ __('You are on the global makesense’s agenda. To discover events taking place in France & in Europe, check out Chiche, our new online media.') }}</mks-text>
        <mks-link to="https://chiche.makesense.org" type="menu">
          <mks-button type="secondary" size="small">{{ __('Let\'s go') }}</mks-button>
        </mks-link>
      </mks-spacer>
      <div class="hide-on-mobile chiche-image-bg mks-col-4@md mks-col-end">
        <nuxt-img
          format="webp"
          sizes="xs:450px sm:450px"
          class="chiche-image"
          alt="chiche_by_makesense"
          src="/assets/images/chiche-bg.png"
        />
      </div>
    </div-->
    <template v-if="events && events.length == 0">
      <div class="events__none" >
        <!-- <mks-alert type="neutral" v-if="lang === 'en'">
          <mks-text tag="p" align="center" size="small" class="empty-state__locale" >
            🇬🇧 {{__('Tu es actuellement sur la version anglaise')}},
            <mks-link to="https://chiche.makesense.org"><mks-button class="link" variant="bare"
              >{{__('voir les événements en français')}}</mks-button
            ></mks-link>
          </mks-text>
        </mks-alert>
        <mks-alert type="neutral" v-if="lang === 'es'">
          <mks-text tag="p" align="center" size="small" class="empty-state__locale" >
            🇪🇸 {{__('Tu es actuellement sur la version espagnole')}},
            <mks-link to="https://chiche.makesense.org"><mks-button class="link" variant="bare"
              >{{__('voir les événements en français')}}</mks-button
            ></mks-link>
          </mks-text>
        </mks-alert> -->
        <EmptyState type="events">
          <template v-slot:cta>
            <mks-link v-if="type === 'events'" :to="$env.factoryUrl + '/' + lang + '/login'" type="menu">
              <mks-button size="small">{{ __("Créer un évènement") }} </mks-button>
            </mks-link>
            <mks-button
              v-if="hasQuery"
              type="neutral"
              size="small"
              @click="resetFilters"
              >{{ __("Réinitialiser ma recherche") }}</mks-button
            >
          </template>
        </EmptyState>
      </div>
    </template>

    <mks-loading type="component" v-else-if="!events"></mks-loading>
  </div>
</template>

<script>
import { reactive } from "vue";
import { useRoute } from "vue-router";
import { mapGetters } from "vuex";
import debounce from "@/helpers/debounce";

import MksPagination from "@DS/components/Pagination.vue";
import EventList from "@/components/organisms/EventList.vue";
import NewsletterHero from "@/components/organisms/NewsletterHero.vue";
import chicheMetas from '@/mixins/chicheMetas'
// todo: check image
export default defineNuxtComponent({
  components: { EventList, MksPagination, NewsletterHero },
  mixins: [chicheMetas],
  props: {
    layout: {
      type: String,
      default: "card",
    },
    topic: {
      type: String,
    },
    momentum: {
      type: String,
    },
    showFilters: {
      type: [String, Array, Boolean],
      default: "all",
    },
    showNewsletterHero: {
      type: Boolean,
      default: true,
    },
    showQuickSearch: {
      type: Boolean,
      default: false,
    },
    showPagination: {
      type: Boolean,
      default: true,
    },
    dateBetween: {
      type: Array,
      default: null,
    },
    listType: {
      type: String,
      default: undefined,
    },
    includePrivate: {
      type: Boolean,
      default: false,
    },
    /** for tracking */
    source: {
      type: String,
    },
    limit: {
      type: Number,
      default: 24,
    },
  },
  async setup(props) {
    //const props = {};
    // const props = useProps({ limit: Number, momentum: String });
    const NuxtApp = useNuxtApp();
    const route = useRoute();
    const data = reactive({
      unfiltered: {},
      events: null,
      total: 0,
      count: 0
    });
    let events, hasQuery;
    try {
      hasQuery = Object.keys(route.query).filter(key => key !== 'results').length <= 0 && !route.params.slug;
      events = await NuxtApp.$api.Events.query({ 
        limit: props.limit, 
        page: 0, 
        lang: NuxtApp.$i18n.locale.value || 'fr', 
        momentum: props.momentum || undefined,
        topic: props.topic || undefined,
        private: props.includePrivate || undefined
      });
    } catch (e) {
      console.log(e);
      //error({ statusCode: 500, message: 'Error fetching data' })
    } finally {
      if (events) {
        data.unfiltered = {
          events: events.data,
          total: events.total,
          count: events.count,
        };
        data.events = events.data;
        data.total = events.total;
        data.count = events.count;
      }
    }
    
    return data;
  },
  data: function () {
    let q = this.$route.query;
    let momentumSlug = this.$route.params.slug;
    let lang = this.lang;
    let momentum = this.momentum;
    let topic = this.topic;
    return {
      loading: false,
      page: 0,
      // total: 0,
      // count: 0,
      events: null,
      // unfiltered: {},
      userTopic: null,
      filters: {
        lang,
        search: q.search || "",
        date:
          q.from || q.to
            ? {
                from: q.from,
                to: q.to,
              }
            : "",
        from: q.from || "",
        to: q.to || "",
        address:
          q.lat || q.lng || q.countryCode
            ? {
                lat: q.lat,
                lng: q.lng,
                countryCode: q.countryCode,
                formattedAddress: q.formattedAddress,
                visio: q.visio,
              }
            : "",
        lat: q.lat || "",
        lng: q.lng || "",
        countryCode: q.countryCode || "",
        formattedAddress: q.formattedAddress || "",
        type: q.type || "",
        hotspot: q.hotspot || "",
        momentum: momentum || momentumSlug || "",
        topic: q.topic || "",
        pillar: q.pillar || "",
        visio: q.visio || "",
        makesense: q.makesense || "",
      },
    };
  },
  computed: {
    ...mapGetters("user", ["userLoading", "isLoggedIn", "profile"]),
    head() {
      if (!this.events) return;
      return this.getEventsStructuredData(this.events);
    },
    isIndexPage() {
      return this.source === "homepage";
    },
    hasQuery() {
      const queryParams = Object.assign({}, Object.keys(this.$route.query));
      delete queryParams.results;
      return (
        queryParams.length > 0 || this.$route.params.slug
      );
    },
    pages() {
      return Math.ceil(this.total / this.limit);
    },
  },
  created() {
    if (!this.hasQuery) {
      this.showUnfiltered();
    }
  },
  mounted() {
    if (this.events) this.$emit('matches', this.events);
    // this.getUserTopic();
  },
  watch: {
    filters: {
      handler(val) {
        let setFilters = Object.assign({}, val);
        let momentum = val.momentum;
        if (setFilters.address.city == "") {
          delete setFilters.lat;
          delete setFilters.lng;
        }
        for (let i in setFilters) {
          if (!setFilters[i]) delete setFilters[i];
        }
        delete setFilters.date;
        delete setFilters.address;
        delete setFilters.momentum;
        delete setFilters.lang;
        if (this.$route.query.previewing === 'true') {
          setFilters.previewing = 'true';
        }

        this.updateQuery(setFilters, momentum);
      },
      deep: true,
    },
    // userLoading() {
    //   this.getUserTopic();
    // },
    events() {
      this.$emit('matches', this.events);
      if (this.events && this.events.length === 0) {
        this.$router.push({
          name: this.$route.name,
          query: Object.assign({}, { ...this.$route.query, results: false }),
        });
        this.$store.dispatch("analytics/track", {
          event: "Front - Search with no results",
          data: {
            label: "Empty search",
            ...this.filters,
          },
        });
        // if ($screeb) {
        //   $screeb('event.track', 'Search - no results', {
        //     ...this.filters
        //   });
        // }
      } else {
        // this.$router.push({
        //   name: this.$route.name,
        //   query: Object.assign({}, { ...this.$route.query, results: undefined }),
        // });
      }
    },
  },
  methods: {
    // async getUserTopic() {
    //   if (!this.isLoggedIn) return undefined;
    // if (this.profile.topic) {
    //   const taxo = await this.$api.Taxonomy.byId(this.profile.topic[0])
    //   console.log(taxo.name[this.lang]);
    //   this.userTopic = {
    //     id: taxo._id,
    //     label: taxo.name[this.lang],
    //     slug: taxo.slug[this.lang]
    //   };
    // }
    // },
    showUnfiltered() {
      this.events = this.unfiltered.events;
      this.total = this.unfiltered.total;
      this.count = this.unfiltered.count;
    },
    updateCurrentPage(page) {
      this.page = page - 1;
      this.$router.push({
        name: this.$route.name,
        query: Object.assign({}, { ...this.$route.query, page: page - 1 }),
      });
      this.fetchEvents();
      this.$nextTick(() => {
        this.$refs["event-top"].$el.scrollIntoView();
      });
    },
    updateQuery(obj, momentum) {
      this.page = 0;
      if (this.isIndexPage) {
        if (momentum) {
          this.$router.push(
            this.localePath({
              name: "home-momentum",
              params: { slug: momentum },
              query: Object.assign({}, { ...obj }),
            })
          );
        } else {
          this.$store.dispatch("momentum/reset");
          this.$router.push(
            this.localePath({
              name: "index",
              query: Object.assign({}, { ...obj }),
            })
          );
        }
      }

      if (Object.keys(obj).length > 0 || momentum) {
        this.fetchEvents();
      } else {
        this.events = this.unfiltered.events;
        this.total = this.unfiltered.total;
        this.count = this.unfiltered.count;
      }
    },
    fetchEvents: debounce(function () {
      this.loading = true;

      let apiQuery = {
        limit: this.limit,
        page: this.page,
        ...this.$route.query,
        private: this.includePrivate || undefined
      };

      Promise.all([
        this.getFilterDatas("type"),
        this.getFilterDatas("topic"),
        this.getFilterDatas("momentum"),
        this.getFilterDatas("hotspot"),
        this.getFilterDatas("pillar"),
      ])
        .then((data) => {
          const type = data[0];
          const topic = data[1];
          const momentum = data[2];
          const hotspot = data[3];
          const pillar = data[4];

          if (momentum) {
            this.$store.dispatch("momentum/set", momentum);
          }

          apiQuery.status = "active";
          apiQuery.lang = this.lang;
          apiQuery.type = type ? type._id : undefined;
          apiQuery.pillar = pillar ? pillar._id : undefined;

          if (this.topic) apiQuery.topic = this.topic;
          else apiQuery.topic = topic ? topic._id : undefined;
          
          if (this.momentum) apiQuery.momentum = this.momentum;
          else apiQuery.momentum = momentum ? momentum._id : undefined;

          apiQuery.hotspot = hotspot ? hotspot._id : undefined;
          apiQuery.from =
            (this.filters.date && this.filters.date.from) || undefined;
          apiQuery.to =
            (this.filters.date && this.filters.date.to) || undefined;
          apiQuery.search = this.filters.search || undefined;
          if (this.filters.address.city == "") {
            apiQuery.countryCode = this.filters.address.countryCode;
          } else {
            apiQuery.loc =
              this.filters.address &&
              this.filters.address.lng &&
              this.filters.address.lat
                ? [this.filters.address.lng, this.filters.address.lat]
                : undefined;
          }
          if (this.filters.visio) apiQuery.visio = this.filters.visio;
          if (this.filters.makesense)
            apiQuery.makesense = this.filters.makesense;

          return this.$api.Events.query(apiQuery);
        })
        .then((res) => {
          this.events = res.data;
          this.total = res.total;
          this.count = res.count;
          this.loading = false;
        })
        .catch((err) => {
          console.error(err);
          this.loading = false;
          this.resetFilters();
        });
    }, 300),
    getFilterDatas(filter) {
      const slug = this.filters[filter];
      if (!slug) {
        return Promise.resolve();
      }
      if (slug) {
        if (filter === "hotspot") {
          return this.$api.Hotspot.bySlug(slug, { lang: this.lang });
        } else {
          return this.$api.Taxonomy.bySlug(slug, {
            lang: this.lang,
            type: filter,
          });
        }
      }
    },
    resetFilters() {
      Object.assign(this.filters, {
        momentum: "",
        date: "",
        from: "",
        to: "",
        topic: "",
        pillar: "",
        address: "",
        lat: "",
        lng: "",
        countryCode: "",
        type: "",
        hotspot: "",
        search: "",
        makesense: "",
        visio: "",
      });
    },
    getMomentum(momentum) {
      if (this.filters.momentum === momentum) {
        this.resetFilters();
        return;
      }
      this.$router.push(
        this.localePath({
          name: "home-momentum",
          params: { slug: momentum, savePosition: true },
        })
      );
      this.$store.dispatch("analytics/track", {
        event: "Front - Use filters",
        data: {
          label: "Quick search",
          filter: "quick-search",
          value: momentum,
        },
      });
    },
    setFilter(filter, value) {
      this.filters[filter] = value;
      // this.$set(this.filters, filter, value);

      this.$store.dispatch("analytics/track", {
        event: "Front - Use filters",
        data: {
          label: "Quick search",
          filter: "quick-search",
          value: filter + " - " + value,
        },
      });
    },
  },
});
</script>

<style lang="scss" scoped>
.events__day-date {
  text-transform: capitalize;
}

.filter-events .events,
.filter-events .events__none {
  @include container-spacing(true, true);
  @include inner-space("l", "t");
  @include inner-space("xxxl", "b");

}

.events,
.events__none {
  @include inner-space("l", "t");
  @include inner-space("xxxl", "b");
}

.chiche-pub {
  @include container-spacing(true, true);
  background: $color-blue-dianne;
  color: $color-neutral-100;
  border-radius: $border-radius-xl;
  padding: 0;
  max-width: 1000px;
  overflow: hidden;

  .chiche-text {
    padding-top: $space-l;
    padding-bottom: $space-l;
    padding-left: $space-l;
    padding-right: 0;

    @include breakpoint('small') {
      padding-top: $space-xxl;
      padding-bottom: $space-xxl;
      padding-left: $space-xxl;
    }
  }

  .chiche-image-bg,
  .chiche-image-bg img {
    height: 100%;
    object-fit: cover;
  }
  
}

.events__none {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}
.filter-events .filters {
  @include container-spacing(true, true);
  @include inner-space("xl", "t");
}

.filters {
  @include inner-space("xs", "t");
  @include inner-space("m", "b");
}
.filter-events .quick-search {
  @include container-spacing(true, true);
}

.quick-search {
  margin-top: -20px;
}
.events__pagination {
  justify-content: center;
}
.tag__paumees {
  color: $color-neutral-100;
  background: #31339a;
  border-color: #31339a;
  &:hover {
    background: #2c2e8d;
    border-color: #31339a;
  }
}
.tag__gangsters {
  color: $color-neutral-100;
  background: #31339a;
  border-color: #31339a;
  &:hover {
    background: #2c2e8d;
    border-color: #31339a;
  }
}
.push-right {
  margin-left: auto;
}

.link {
  text-decoration: underline !important;
  cursor: pointer;
}

// .empty-state__locale {
//   margin-top: $space-s;
// }
</style>
