export const calculatePercentages = (data) => {
  // Calculer le total des réponses
  const totalResponses = data.reduce((sum, count) => sum + count, 0);

    // Calculer les pourcentages exacts
    const exactPercentages = data.map(count => (count / totalResponses) * 100);

    // Arrondir les pourcentages à des nombres entiers
    const roundedPercentages = exactPercentages.map(percent => Math.round(percent));

    // Calculer le total des pourcentages arrondis
    let totalRounded = roundedPercentages.reduce((sum, percent) => sum + percent, 0);

    // Ajuster les pourcentages pour que le total fasse 100%
    if (totalRounded !== 100) {
        const difference = 100 - totalRounded;
        adjustPercentages(roundedPercentages, exactPercentages, difference);
    }

    return roundedPercentages;
}

function adjustPercentages(roundedPercentages, exactPercentages, difference) {
    // Trouver les indices des pourcentages à ajuster
    const adjustments = exactPercentages.map((percent, index) => ({
        index,
        difference: Math.abs(percent - roundedPercentages[index])
    }));

    // Trier les ajustements par différence décroissante
    adjustments.sort((a, b) => b.difference - a.difference);

    // Appliquer les ajustements
    for (let i = 0; i < Math.abs(difference); i++) {
        const adjustment = adjustments[i];
        roundedPercentages[adjustment.index] += Math.sign(difference);
    }
}

// Exemple d'utilisation
// const data = [40, 60, 20]; // Nombre de réponses pour chaque option
// const percentages = calculatePercentages(data);
// console.log(percentages); // Affiche les pourcentages ajustés
