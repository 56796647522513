// import your custom components
// import MksText from '@makesenseorg/design-system/vue3/components/Text.vue'



import ArticleTeaser from './components/organisms/Article/Teaser.vue';
import Button from './components/atoms/Button.vue'
import BuzzingArticles from './components/organisms/BuzzingArticles.vue';
import BuzzingSidebarArticles from './components/organisms/Article/BuzzingSidebarArticles.vue';
import Breadcrumbs from './components/molecules/Breadcrumbs.vue';
import Carousel from './components/organisms/Carousel.vue';
import EventTeaser from './components/organisms/Event/Teaser.vue';
import ExpertBean from './components/molecules/ExpertBean.vue';
import Heading from './components/atoms/Heading.vue';
import NewsletterCard from './components/organisms/NewsletterCard.vue';
import NewsletterHero from './components/organisms/NewsletterHero.vue';
import PageHeader from './components/molecules/PageHeader.vue';
import Quote from './components/atoms/Quote.vue';
import Section from './components/atoms/Section.vue';
import SidebarStickyGroup from './components/atoms/SidebarStickyGroup.vue';
import SponsoredBy from './components/molecules/SponsoredBy.vue';
import Teaser from './components/molecules/Teaser.vue';
import CardLink from './components/molecules/CardLink.vue';
import FilterEvents from './components/organisms/FilterEvents.vue';
import MksButtonProxy from './components/atoms/MksButtonProxy.vue';
import ActionCard from './components/organisms/ActionCard.vue';
import ActionCardBase from './components/organisms/ActionCardBase.vue';
import CurrentSurvey from './components/organisms/Survey/CurrentSurvey.vue';

const BUTTON_COLORS = [
  'neutral', 
  'primary', 
  'secondary', 
  'tertiary',
  'atlantis',
  'blue-dianne',
  'brick-red',
  'broom',
  'calypso',
  'cerise',
  'flame-pea',
  'parsley',
  'roman',
  'sahara-sand',
  'silver-tree',
  'zeus',
  'positive', 
  'warning', 
  'negative', 
];
const COLORS = [
  'primary', 
  'secondary', 
  'tertiary',
  'atlantis',
  'blue-dianne',
  'brick-red',
  'broom',
  'calypso',
  'cerise',
  'flame-pea',
  'parsley',
  'roman',
  'sahara-sand',
  'silver-tree',
  'zeus',
  'positive', 
  'warning', 
  'negative',
  'neutral-0', 
  'neutral-50', 
  'neutral-90', 
  'neutral-100', 
];

const eventTeaserVariants = ['flat', 'square', 'spotlight', 'line', 'spotlight-auto'];
const articleTeaserVariants = ['text', 'flat', 'square', 'spotlight', 'line'];

export const REGISTERED_COMPONENTS = [
  // {
  //   component: MksText,
  //   name: 'MksText',
  //   inputs: [
  //     {
  //       name: 'text',
  //       type: 'richText',
  //       friendlyName: 'Texte'
  //     },
  //     {
  //       name: 'align',
  //       type: 'string',
  //       defaultValue: 'left',
  //       enum: ['left', 'right', 'center', 'justify'],
  //       friendlyName: 'Alignement'
  //     },
  //     {
  //       name: 'color',
  //       type: 'color',
  //       enum: ["inherit", "text", "light", "primary", "secondary", "tertiary", "positive", "warning", "negative"],
  //       defaultValue: 'inherit',
  //       friendlyName: 'Couleur'
  //     },
  //     {
  //       name: 'size',
  //       type: 'string',
  //       defaultValue: 'normal',
  //       enum: ['small', 'normal', 'large'],
  //       friendlyName: 'Taille'
  //     },
  //     {
  //       name: 'weight',
  //       type: 'string',
  //       defaultValue: 'normal',
  //       enum: ['normal', 'semibold', 'bold'],
  //       friendlyName: 'Graisse'
  //     },
  //   ],
  // },
  {
    component: Heading,
    name: 'Heading',
    image: 'https://cdn-icons-png.flaticon.com/512/8988/8988927.png',
    inputs: [
      {
        name: 'textBefore',
        type: 'string',
        friendlyName: 'Texte avant le bean'
      },
      {
        name: 'textBean',
        type: 'string',
        friendlyName: 'Texte du bean'
      },
      {
        name: 'textAfter',
        type: 'string',
        friendlyName: 'Texte après le bean'
      },
      {
        name: 'colorText',
        type: 'string',
        friendlyName: 'Couleur du texte',
        enum: COLORS,
        defaultValue: 'primary'
      },
      {
        name: 'colorBean',
        type: 'string',
        friendlyName: 'Couleur du bean',
        enum: BUTTON_COLORS,
        defaultValue: 'secondary'
      },
      {
        name: 'customColorBean',
        type: 'string',
        friendlyName: 'Couleur custom du bean',
        defaultValue: '',
        helperText: 'Couleur valide en css (#RRGGBB, #RGB, rgba(255, 255, 255, 0.5), etc), surcharge la couleur précédente'
      },
      {
        name: 'tag',
        type: 'string',
        friendlyName: 'Titre (SEO)',
        defaultValue: 'h2',
        enum: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'],
        helperText: 'Tag utilisé pour le SEO. Attention, h1 = titre de la page, il ne doit y en avoir qu\'un seul. Les Titres doivent être utilisés par ordre de grandeur'
      },
      // TODO / tagclass ne fonctionne pas ché pas pk
      {
        name: 'tagClass',
        type: 'string',
        friendlyName: 'Titre (Design)',
        defaultValue: 'h2',
        enum: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'],
        helperText: 'Ce champs permet d\'afficher une taille différente que celle normalement associé au tag SEO sélectionné avant. Par exemple, si l\'on veut afficher un h2 mais dans une taille h4 ou inversement.'
      },
    ]
  },
  {
    component: Button,
    name: 'Button',
    image: 'https://cdn.icon-icons.com/icons2/2717/PNG/512/link_simple_horizontal_icon_173739.png',
    inputs: [
      {
        name: 'label',
        type: 'string',
      },
      {
        name: 'type',
        type: 'string',
        friendlyName: 'Thème',
        enum: BUTTON_COLORS,
        helperText: 'La couleur du bouton'
      },
      {
        name: 'variant',
        type: 'string',
        friendlyName: 'Variante',
        enum: ['full', 'ghost', 'text', 'bare'],
        defaultValue: 'full',
        helperText: 'Si le bouton est rempli, vide ou bien en mode texte'
      },
      {
        name: 'size',
        friendlyName: 'Taille',
        type: 'string',
        enum: ['small', 'medium', 'default', 'full', 'round'],
        defaultValue: 'small',
        helperText: 'Utiliser "round" quand le bouton ne contient qu\'une icône et pas de texte'
      },
      {
        name: 'icon',
        friendlyName: 'Icône (optionnel)',
        type: 'string',
        helperText: 'Choisir parmi les icônes ici : https://feathericons.com/. Ex: "activity"'
      },
      {
        name: 'icon-right',
        friendlyName: 'Afficher l\'icône à droite du texte',
        type: 'boolean',
        default: false
      },
      {
        name: 'animate-icon',
        friendlyName: 'Afficher l\'icône au hover uniquement',
        type: 'boolean',
        default: false
      },
      {
        name: 'disabled',
        friendlyName: 'Etat désactivé',
        type: 'boolean',
        default: false,
        advanced: true
      },
      {
        name: 'loading',
        friendlyName: 'Etat chargement',
        type: 'boolean',
        default: false,
        advanced: true
      },
      {
        name: 'tag',
        friendlyName: 'Tag HTML',
        type: 'string',
        advanced: true
      },
      {
        name: 'role',
        type: 'string',
        advanced: true
      },
      {
        name: 'input-type',
        type: 'string',
        advanced: true
      }

    ]
  },
  {
    component: PageHeader,
    name: 'PageHeader',
    image: 'https://cdn-icons-png.flaticon.com/512/3596/3596232.png',
    canHaveChildren: true,
    inputs: [
      {
        name: 'background',
        type: 'color',
        friendlyName: 'Couleur du fond',
        defaultValue: '#0c3944'
      },
      {
        name: 'text-color',
        type: 'color',
        friendlyName: 'Couleur du text',
        defaultValue: '#FFFFFF'
      },
      {
        name: 'image',
        type: 'file',
        friendlyName: 'Image de fond (remplace la couleur de fond)'
      },
      {
        name: 'imageAlt',
        type: 'text',
        friendlyName: 'Description de l\'image (SEO et accessibilité)'
      },
    ]
  },
  {
    component: Section,
    name: 'Section',
    image: 'https://static.thenounproject.com/png/2821172-200.png',
    canHaveChildren: true,
    defaultChildren: [
      { 
        '@type': '@builder.io/sdk:Element',
         component: { name: 'Text', options: { text: 'Je suis un bout de texte!' } }
      }
    ],
    inputs: [
      {
        name: 'background',
        type: 'color',
        friendlyName: 'Couleur du fond',
        defaultValue: '#FFFFFF'
      },
      {
        name: 'color',
        type: 'color',
        friendlyName: 'Couleur du texte',
      },
      {
        name: 'noRightPadding',
        type: 'boolean',
        friendlyName: 'Retirer la marge à droite uniquement',
        defaultValue: false
      },
      {
        name: 'noHorizontalPadding',
        type: 'boolean',
        friendlyName: 'Retirer toutes les marges horizontales',
        defaultValue: false
      },
      {
        name: 'noVerticalPadding',
        type: 'boolean',
        friendlyName: 'Retirer les marges verticales',
        defaultValue: false
      },
      {
        name: 'noInnerWrapper',
        type: 'boolean',
        friendlyName: 'Ne pas wrapper les éléments enfants',
        defaultValue: false,
        advanced: true,
        helperText: 'Passer à true si le layout semble cassé'
      },
      {
        name: 'radius',
        type: 'string',
        friendlyName: 'Afficher des bordures arrondies',
        defaultValue: 'none',
        enum: ['none', 'top', 'bottom', 'both'],
        helperText: 'Passer à true si le layout semble cassé'
      }
    ]
  },
  {
    component: Quote,
    name: 'Quote',
    image: 'https://static-00.iconduck.com/assets.00/blockquote-icon-512x397-42zg6rqn.png',
    inputs: [
      {
        name: 'text',
        type: 'richText',
        friendlyName: 'Texte'
      },
      {
        name: 'author',
        type: 'richText',
        friendlyName: 'Auteurice'
      },
      {
        name: 'color',
        type: 'color',
        friendlyName: 'Couleur'
      },
    ]
  },
  {
    component: NewsletterCard,
    name: 'NewsletterCard',
    image: 'https://cdn-icons-png.flaticon.com/512/2089/2089181.png',
    inputs: [
      {
        name: 'titleBefore',
        friendlyName: 'Heading avant bean',
        type: 'string',
        defaultValue: 'Le Cachet du lundi est mort, vive la '
      },
      {
        name: 'titleBean',
        friendlyName: 'Heading bean',
        type: 'string',
        defaultValue: 'chiche'
      },
      {
        name: 'titleAfter',
        friendlyName: 'Heading après bean',
        type: 'string',
        defaultValue: 'letter !'
      },
      {
        name: 'description',
        type: 'richText',
        friendlyName: 'description',
        defaultValue: 'Tous les lundis, reçois un shoot d’infos, de rendez-vous et d’actions pour tenir toute la semaine.'
      },
      {
        name: 'hideDescription',
        type: 'boolean',
        friendlyName: 'Cacher la description',
        helperText: 'Cocher si la description est vide et qu\'un espace non désiré est entre le titre et le champs email',
        defaultValue: false
      },
      {
        name: 'image',
        type: 'file',
        friendlyName: 'Image'
      },
      {
        name: 'size',
        type: 'string',
        enum: ['small', 'normal'],
        defaultValue: 'normal',
        friendlyName: 'Taille'
      },
      {
        name: 'theme',
        type: 'string',
        enum: ['neutral', 'parsley'],
        defaultValue: 'neutral',
        friendlyName: 'Thème'
      },
      {
        name: 'source',
        type: 'string',
        defaultValue: 'chiche-',
        friendlyName: 'Source'
      }
    ]
  },
  {
    component: BuzzingSidebarArticles,
    name: 'Buzzing Sidebar Articles',
    image: 'https://cdn-icons-png.flaticon.com/512/10332/10332464.png',
    models: ['article-sidebar'],
    inputs: [
      {
        name: 'title',
        type: 'string',
        friendlyName: 'Titre',
        defaultValue: 'Ça buzze'
      },
      {
        name: 'limit',
        type: 'number',
        friendlyName: 'Nombre d\'articles',
        defaultValue: 3
      },
      {
        name: 'weeksAgo',
        type: 'number',
        friendlyName: 'Nombre de semaines en arrière',
        defaultValue: 12
      }
    ]
  },
  {
    component: SidebarStickyGroup,
    name: 'Sidebar Sticky Group',
    image: 'https://cdn-icons-png.flaticon.com/512/8291/8291371.png',
    models: ['article-sidebar'],
    canHaveChildren: true,
    noWrap: true
  },
  {
    component: Breadcrumbs,
    name: 'Breadcrumbs',
    image: 'https://cdn-icons-png.flaticon.com/512/6961/6961742.png',
    inputs: [
      {
        name: 'items',
        type: 'list',
        friendlyName: 'Items',
        defaultValue: [ 
          { label: 'Accueil', path: '/' }],
        subFields: [
          {
            name: 'label',
            type: 'string',
            friendlyName: 'Label',
          },
          {
            name: 'path',
            type: 'string',
            helperText: 'Une URL absolue commneçant par "https://" ou une URL relative au site : "/media/environnement"'
          },
        ],
      },
      {
        name: 'color',
        type: 'string',
        enum: COLORS,
        friendlyName: 'Couleur',
      }
    ]
  },
  {
    component: ArticleTeaser,
    name: 'Article',
    image: 'https://icons.veryicon.com/png/o/miscellaneous/yuanql/icon-article.png',
    inputs: [
      {
        name: 'content',
        type: 'reference',
        friendlyName: 'Article',
        model: 'article',
        required: true
      },
      {
        name: 'variant',
        type: 'string',
        enum: articleTeaserVariants,
        defaultValue: 'text'
      }
    ]
  },
  {
    component: CurrentSurvey,
    name: 'Current Survey',
    image: 'https://cdn-icons-png.flaticon.com/512/6964/6964627.png',
    inputs: [
      {
        name: 'background',
        type: 'file',
        friendlyName: 'Image de fond'
      },
      {
        name: 'title',
        type: 'string',
        friendlyName: 'Titre',
        defaultValue: 'Les chiches sondages'
      }
    ]
  },
  {
    component: EventTeaser,
    name: 'Event',
    image: 'https://www.freeiconspng.com/thumbs/calendar-icon-png/calendar-date-event-month-icon--19.png',
    inputs: [
      {
        name: 'slug',
        type: 'string',
        friendlyName: 'Slug de l\'événement',
        helperText: 'Se trouve dans l\'URL de l\'évènement : events.makesense.org/e/SLUG'
      },
      {
        name: 'event',
        type: 'object',
        friendlyName: 'Evenement',
        helperText: 'Remplir uniquement si le slug n\'est pas rempli avec des données automatique'
      },
      {
        name: 'variant',
        type: 'string',
        enum: eventTeaserVariants,
        defaultValue: 'text'
      },
      {
        name: 'image',
        type: 'file',
      },
      {
        name: 'useCoverDisplay',
        type: 'boolean',
        friendlyName: 'Afficher la cover Display qui remplace la cover originale sur les événements',
        helperText: 'Cette cover est mise dans le BO (bo.factory.makesense.org)',
        defaultValue: 'true'
      },
      {
        name: 'track',
        type: 'string',
        friendlyName: 'Tracking',
        helperText: 'Nom de l\'événement de tracking',
        defaultValue: ''
      },
    ]
  },
  {
    component: Teaser,
    name: 'Teaser',
    image: 'https://imgs.search.brave.com/d6R_vYcDdv_zdTQV5Dw6JchwwjgxITDHgqjiVLDtcks/rs:fit:860:0:0/g:ce/aHR0cHM6Ly9zdGF0/aWMudGhlbm91bnBy/b2plY3QuY29tL3Bu/Zy8yODYzMTEzLTIw/MC5wbmc',
    inputs: [
      {
        name: 'variant',
        type: 'string',
        enum: articleTeaserVariants,
        defaultValue: 'text'
      },
      {
        name: 'to',
        type: 'string',
        friendlyName: 'Lien',
        defaultValue: 'https://chiche.makesense.org'
      },
      {
        name: 'title',
        type: 'string',
        friendlyName: 'Titre',
        defaultValue: 'Titre du teaser'
      },
      {
        name: 'text',
        type: 'string',
        friendlyName: 'Description',
      },
      {
        name: 'target',
        type: 'string',
        friendlyName: 'Target',
        defaultValue: '_blank',
        helperText: 'Est utilisé uniquement sur la variante flat. "_blank" ouvre le lien dans un nouvel onglet'
      },
      {
        name: 'image',
        type: 'file',
        friendlyName: 'Image',
      },
      {
        name: 'tagLabel',
        type: 'string',
        friendlyName: 'Texte du tag (optionnel)',
      },
      {
        name: 'tagColor',
        type: 'string',
        enum: COLORS,
        friendlyName: 'Couleur du texte du tag (optionnel)',
      },
      {
        name: 'tagBGColor',
        type: 'string',
        enum: COLORS,
        friendlyName: 'Couleur de fond du tag (optionnel)',
      },
      {
        name: 'tagIcon',
        type: 'string',
        friendlyName: 'Icône du tag (optionnel)',
        helperText: 'Choisir parmi https://feathericons.com/'
      },
    ]
  },
  {
    component: CardLink,
    name: 'Card Link',
    image: 'https://static.thenounproject.com/png/875784-200.png',
    canHaveChildren: true,
    inputs: [
      {
        name: 'text',
        type: 'text',
        friendlyName: 'Texte',
      },
      {
        name: 'to',
        type: 'path',
        friendlyName: 'Lien',
        helperText: 'une URL commençant par "http://" OU un lien relative comme "/media"'
      },
      {
        name: 'background',
        type: 'color',
        friendlyName: 'Couleur du fond',
      },
      {
        name: 'textColor',
        type: 'color',
        friendlyName: 'Couleur du texte',
      },
      {
        name: 'buttonBackground',
        type: 'color',
        friendlyName: 'Fond du bouton',
        helperText: 'Laisser vide pour matcher la couleur avec le texte'
      },
      {
        name: 'noStyling',
        type: 'boolean',
        friendlyName: 'Enlever le style par défaut du texte',
        defaultValue: false
      },
    ]
  },
  {
    component: Carousel,
    name: 'Carousel',
    image: 'https://static.thenounproject.com/png/258013-200.png',
    canHaveChildren: true,
    defaultChildren: [
      { 
        '@type': '@builder.io/sdk:Element',
        component: { name: 'Text', options: { text: 'Je suis un bout de texte!' } }
      }
    ],
    childRequirements: {
      query: {
        'component.name': { $nin: ['Columns'] }
      }
    },
    inputs: [
      {
        name: 'slidesPerView',
        type: 'number',
        friendlyName: 'Nombre de slides visible sur desktop',
        helperText: 'Le nombre sur mobile se calcule automatiquement',
        defaultValue: 4
      },
      {
        name: 'viewMoreLink',
        type: 'string',
        friendlyName: 'Lien relatif ou https:// du bouton',
        defaultValue: '/media/'
      },
      {
        name: 'viewMoreLabel',
        type: 'string',
        friendlyName: 'Label du bouton',
        defaultValue: 'Découvrir'
      },
      {
        name: 'viewMoreColor',
        type: 'string',
        friendlyName: 'Couleur du bouton',
        enum: BUTTON_COLORS,
        defaultValue: 'primary'
      },
      {
        name: 'showNavigation',
        type: 'boolean',
        friendlyName: 'Afficher la navigation précédent/suivant',
        defaultValue: true
      },
      {
        name: 'navColor',
        type: 'string',
        friendlyName: 'Couleur de la navigation',
        enum: BUTTON_COLORS,
        defaultValue: 'primary'
      },
      {
        name: 'articleVariant',
        type: 'string',
        friendlyName: 'Variation pour les articles',
        enum: articleTeaserVariants,
        defaultValue: 'square'
      },
      {
        name: 'query',
        type: 'reference',
        model: 'query',
        // advanced: true
      },
      {
        name: 'ownQuery',
        type: 'object',
        showIf: (options) => !options.get('query'),
        subFields: [
          {
            name: 'topics',
            type: 'list',
            subFields: [
              {
                name: 'content',
                type: 'reference',
                friendlyName: 'Topics',
                model: 'topic',
              },
            ]
          },
          {
            name: 'tags',
            type: 'tags',
            friendlyName: 'Tags',
          },
          {
            name: 'momentums',
            type: 'list',
            subFields: [
              {
                name: 'content',
                type: 'reference',
                friendlyName: 'Momentums',
                model: 'momentum',
              },
            ]
          },
          {
            name: 'eventCriterias',
            type: 'object',
            subFields: [
              {
                name: 'organizedByMakesense',
                type: 'boolean',
                friendlyName: 'Organisé par makesense',
              },
              {
                name: 'maxDate',
                type: 'date',
                friendlyName: 'Date maximum de l\'événement',
              },
              {
                name: 'visio',
                type: 'string',
                defaultValue: 'En visio ou en présentiel',
                enum: ['Uniquement en visio', 'Uniquement en présentiel', 'En visio ou en présentiel'],
                friendlyName: 'Visio / PHysique',
              },
              {
                name: 'city',
                type: 'string',
                friendlyName: 'Ville',
              },
            ]
          },
          {
            name: 'articles',
            type: 'list',
            subFields: [
              {
                name: 'content',
                type: 'reference',
                friendlyName: 'Article',
                model: 'article',
              },
            ]
          },
          {
            name: 'events',
            type: 'list',
            subFields: [
              {
                name: 'content',
                type: 'string',
                friendlyName: 'Event URL',
              },
            ]
          },
        ]
      },
      {
        name: 'useCoverDisplay',
        type: 'boolean',
        friendlyName: 'Afficher la cover Display qui remplace la cover originale sur les événements',
        defaultValue: 'true'
      },
      {
        name: 'builder',
        type: Boolean,
        defaultValue: true,
        showIf: false,
        advanced: true
      },
      {
        name: 'centerInsufficientSlides',
        type: 'boolean',
        defaultValue: false,
        friendlyName: 'Centrer le contenu si insuffisant',
        helperText: 'Si le contenu ne rempli pas la largeur de l\'écran, centrer les éléments.'
      }
    ]
  },
  {
    component: BuzzingArticles,
    name: 'Articles qui buzzent',
    image: 'https://cdn-icons-png.flaticon.com/512/4320/4320247.png',
    inputs: [
      {
        name: 'textBeforeBean',
        type: 'text',
        friendlyName: 'Texte avant bean',
        default: 'Les poids '
      },
      {
        name: 'textBean',
        type: 'text',
        friendlyName: 'Texte bean',
        defaultValue: 'chiche'
      },
      {
        name: 'textAfterBean',
        type: 'text',
        friendlyName: 'Texte après bean',
        defaultValue: ' qui buzzent'
      },
      {
        name: 'numberOfArticles',
        type: 'number',
        friendlyName: 'Nombre d\'articles à afficher',
        defaultValue: 6
      },
      // {
      //   name: 'articles',
      //   type: 'list',
      //   subFields: [
      //     {
      //       name: 'content',
      //       type: 'reference',
      //       friendlyName: 'Article',
      //       model: 'article',
      //     },
      //   ]
      // }
    ]
  },
  {
    component: NewsletterHero,
    name: 'Newsletter Section',
    image: 'https://cdn-icons-png.flaticon.com/512/2089/2089181.png',
    inputs: [
      {
        name: 'image',
        type: 'file',
        friendlyName: 'Image'
      },
      {
        name: 'textBefore',
        friendlyName: 'Heading avant bean',
        type: 'string',
        defaultValue: 'La '
      },
      {
        name: 'textBean',
        friendlyName: 'Heading bean',
        type: 'string',
        defaultValue: 'Chiche'
      },
      {
        name: 'textAfter',
        friendlyName: 'Heading après bean',
        type: 'string',
        defaultValue: 'Letter, pour des lundis aussi excitants qu’un lama en rollers'
      },
      {
        name: 'description',
        type: 'string',
        defaultValue: 'Tous les lundis, reçois un shoot d’infos, de rendez-vous et d’actions pour tenir toute la semaine.'
      },
      {
        name: 'source',
        friendlyName: 'Source',
        helperText: 'pour mailchimp',
        type: 'string',
        defaultValue: 'chiche-'
      },
      {
        name: 'background',
        type: 'color',
        friendlyName: 'Couleur du fond',
        defaultValue: '#FFFFFF'
      },
      {
        name: 'textColor',
        type: 'color',
        friendlyName: 'Couleur du texte',
      },
    ]
  },
  {
    component: SponsoredBy,
    name: 'Partenariat',
    image: 'https://icons.veryicon.com/png/o/miscellaneous/linear-icon-14/partner-5.png',
    inputs: [
      {
        name: 'sponsoredBy',
        type: 'reference',
        friendlyName: 'En partenariat avec',
        model: 'partenariat',
        required: true
      },
      {
        name: 'theme',
        type: 'string',
        enum: ['light', 'dark'],
        defaultValue: 'dark'
      },
      {
        name: 'text',
        type: 'string',
        defaultValue: 'En partenariat avec '
      }
    ]
  },
  {
    component: FilterEvents,
    name: 'FilterEvents',
    image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS2MG_W1jNRil_pmu_z9vRVPZOXVU1DJul3Zg&usqp=CAU',
    inputs: [
      {
        name: 'limit',
        type: 'number',
        friendlyName: 'Limite',
        defaultValue: '3',
        helperText: 'Evenements par page'
      },
      {
        name: 'source',
        type: 'string',
        friendlyName: 'Source (tracking)',
      },
      {
        name: 'momentum',
        type: 'string',
        friendlyName: 'Momentum',
        helperText: 'Optionnel. ID du momentum. Pour préfiltrer la liste.'
      },
      {
        name: 'topic',
        type: 'string',
        friendlyName: 'Sujet',
        helperText: 'Optionnel. ID du topic. Pour préfiltrer la liste.'
      },
      {
        name: 'showPagination',
        type: 'boolean',
        friendlyName: 'Afficher la pagination',
        defaultValue: true
      },
      {
        name: 'includePrivate',
        type: 'boolean',
        friendlyName: 'Inclure les événements privés',
        defaultValue: false
      },
      {
        name: 'showFilters',
        type: 'boolean',
        friendlyName: 'Afficher les filtres',
        defaultValue: false,
        enum: ['card']
      },
      {
        name: 'showNewsletterHero',
        type: 'boolean',
        friendlyName: 'Afficher le bandeau newsletter',
        defaultValue: false,
        advanced: true
      },
      {
        name: 'listType',
        type: 'string',
        friendlyName: 'Type de liste',
        defaultValue: 'grid',
        enum: ['grid'],
        advanced: true
      },
      // {
      //   name: 'layout',
      //   type: 'string',
      //   friendlyName: 'Layout des cards',
      //   defaultValue: 'card',
      //   enum: ['card']
      // },

    ]
  },
  {
    component: ExpertBean,
    name: 'ExpertBean',
    image: 'https://icons.veryicon.com/png/o/miscellaneous/linear-icon-14/partner-5.png',
    inputs: [
      {
        name: 'direction',
        type: 'string',
        enum: ['horizontal', 'vertical'],
        defaultValue: 'horizontal',
        required: true
      },
      {
        name: 'size',
        type: 'string',
        enum: ['small', 'large', 'xlarge'],
        defaultValue: 'large',
        required: true
      },
      {
        name: 'image',
        type: 'file',
        friendlyName: 'Image'
      },
    ]
  },
  {
    component: MksButtonProxy,
    name: 'MksButton',
    image: 'https://icons.veryicon.com/png/o/miscellaneous/linear-icon-14/partner-5.png',
    inputs: [
      {
        name: 'icon',
        type: 'string'
      },
      {
        name: 'size',
        type: 'string',
        enum: ["default", "small", "medium", "full", "round"],
        defaultValue: 'default',
        required: true
      },
      {
        name: 'text',
        type: 'string'
      },
      {
        name: 'iconRight',
        type: 'boolean',
        friendlyName: 'Icone à droite',
        defaultValue: false,
      },
      {
        name: 'animateIcon',
        type: 'boolean',
        friendlyName: 'Icone animée',
        defaultValue: false,
      },
      {
        name: 'type',
        type: 'string',
        friendlyName: 'Couleur',
        enum: BUTTON_COLORS,
        defaultValue: 'secondary'
      },
      {
        name: 'variant',
        type: 'string',
        enum: ["full", "ghost", "text", "bare"],
        defaultValue: 'full'
      },
    ]
  },
  {
    component: ActionCard,
    name: 'ActionCard',
    image: 'https://icons.veryicon.com/png/o/miscellaneous/yuanql/icon-article.png',
    inputs: [
      {
        name: 'action',
        type: 'reference',
        friendlyName: 'Action',
        model: 'carte-action',
        required: true
      },
      {
        name: 'backgroundColor',
        type: 'color',
        friendlyName: 'Couleur du fond',
        defaultValue: '#FFFFFF'
      },
      {
        name: 'textColor',
        type: 'color',
        friendlyName: 'Couleur du texte',
      },
      {
        name: 'buttonColor',
        type: 'color',
        friendlyName: 'Couleur du bouton',
      },
      {
        name: 'arrowColor',
        type: 'color',
        friendlyName: 'Couleur de la fleche',
      },
    ]
  },
  {
    component: ActionCardBase,
    name: 'ActionCardCustom',
    image: 'https://icons.veryicon.com/png/o/miscellaneous/yuanql/icon-article.png',
    inputs: [
      {
        name: 'cover',
        type: 'file',
        friendlyName: 'Cover'
      },
      {
        name: 'title',
        type: 'string',
        friendlyName: 'Titre',
        defaultValue: ''
      },
      {
        name: 'text',
        type: 'longText',
        friendlyName: 'Texte',
        defaultValue: ''
      },
      {
        name: 'link',
        type: 'string',
        friendlyName: 'Lien',
        defaultValue: 'https://chiche.makesense.org'
      },
      {
        name: 'backgroundColor',
        type: 'color',
        friendlyName: 'Couleur du fond',
        defaultValue: '#FFFFFF'
      },
      {
        name: 'textColor',
        type: 'color',
        friendlyName: 'Couleur du texte',
      },
      {
        name: 'buttonColor',
        type: 'color',
        friendlyName: 'Couleur du bouton',
      },
      {
        name: 'arrowColor',
        type: 'color',
        friendlyName: 'Couleur de la fleche',
      }
    ]
  }
]

